import React from 'react';
import CattleFeedHome from './CattleFeedHome';
import Header from '../components/Cattle-feed/Header';

export default function CattleFeed() {
  return (
    <>
      <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
        <Header />
        <CattleFeedHome />
      </div>
    </>
  );
}
