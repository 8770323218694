import React from 'react';
import Header from '../components/Cattle-feed/Header';
import ListItem from '../components/ListItem';
import { data } from '../HorseData/ProductsForHorses';
import Footer from '../components/Cattle-feed/Footer';

export default function GoatAndSheepList() {
  console.log('GoatsAndSheepsdata -> ', data);
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      {data
        .filter(i => i.type === 'goat/sheep')
        .map(goatAndSheeps => {
          return (
            <ListItem
              name={goatAndSheeps.name || 'No Name'}
              description={goatAndSheeps.description || 'No Description'}
              ingredientValues={goatAndSheeps.ingredientValues || 'No Ingredient Values'}
              image={goatAndSheeps.image}
              id={goatAndSheeps.id}
            />
          );
        })}
      <Footer />
    </div>
  );
}
