import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import brochurePDF from '../../components/pdf/brochure.pdf';
import apeda from '../../images/carousel/apeda.png';
import spiceboard from '../../images/carousel/spices-board.jpeg';
import ISO from '../../images/carousel/ISO.webp';
import haccp from '../../images/carousel/haccp.webp';
import gmp from '../../images/carousel/gmp.jpg';
import isoLogo from '../../images/carousel/fda.jpg';

export default function Footer() {
  return (
    <footer style={{ backgroundColor: '#7B0323', padding: '50px 0' }}>
      <style>
        {`
          @media (max-width: 768px) {
            .footer-columns {
              flex-direction: column;
              align-items: center;
            }
            .footer-column {
              width: 100% !important;
              margin-bottom: 20px !important;
            }
            .footer-column h4 {
              text-align: center;
            }
            .social-links {
              text-align: center;
            }
          }

          @media (max-width: 480px) {
            .footer-text {
              font-size: 18px !important;
            }
            .footer-links a {
              font-size: 16px !important;
            }
            .footer-columns {
              padding: 0 20px;
            }
            .social-links a {
              width: 30px !important;
              height: 30px !important;
              line-height: 30px !important;
            }
          }
        `}
      </style>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '50px', gap: '60px' }}>
        <img
          src="assets/images/logo/EI.png"
          alt="Company Logo"
          className="footer-img"
          style={{
            maxWidth: '150px',
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '5px',
          }}
        />
        <div style={{ textAlign: 'center' }}>
          <p className="footer-text" style={{ fontSize: '25px', color: '#ffffff', lineHeight: '1.5', fontWeight: 500 }}>
            GST Number : 24BGXPS9639B1ZQ (EDGE INFINIUM PROPRIETORSHIP) <br />
            GST Number : 24AAHCE4491R1ZZ (EDGE INFINIUM PVT LTD)
          </p>
        </div>
      </div>
      <div
        className="footer-columns"
        style={{ maxWidth: '1170px', margin: 'auto', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}
      >
        <div className="footer-column" style={{ width: '25%', marginBottom: '30px' }}>
          <h4 style={{ fontSize: '24px', color: '#ffffff', textTransform: 'uppercase', marginBottom: '15px' }}>Company</h4>
          <ul className="footer-links" style={{ listStyle: 'none', padding: 0 }}>
            <li>
              <a href="husk-n-spices" style={{ fontSize: '20px', color: '#ffffff', fontWeight: 500, textDecoration: 'none' }}>
                Home
              </a>
            </li>
            <li>
              <a href="husknspicesabout" style={{ fontSize: '20px', color: '#ffffff', fontWeight: 500, textDecoration: 'none' }}>
                About Us
              </a>
            </li>
            <li>
              <a
                href="/feed-grade-vegetable-and-grain-powder"
                style={{ fontSize: '20px', color: '#ffffff', fontWeight: 500, textDecoration: 'none' }}
              >
                Feed Grade Vegetable and Grain Powder
              </a>
            </li>
            <li>
              <a href="/peel-powder" style={{ fontSize: '20px', color: '#ffffff', fontWeight: 500, textDecoration: 'none' }}>
                Peel Powder (Skin Glowing Powder)
              </a>
            </li>
            <li>
              <a href="/dehydrated-vegetable-powder" style={{ fontSize: '20px', color: '#ffffff', fontWeight: 500, textDecoration: 'none' }}>
                Dehydrated Vegetable Powder
              </a>
            </li>
            <li>
              <a href="/spray-drayed-fruits-powder" style={{ fontSize: '20px', color: '#ffffff', fontWeight: 500, textDecoration: 'none' }}>
                Spray Drayed Fruits Powder
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column" style={{ width: '25%', marginBottom: '30px' }}>
          <h4 style={{ fontSize: '24px', color: '#ffffff', textTransform: 'uppercase', marginBottom: '15px' }}>Get Help</h4>
          <ul className="footer-links" style={{ listStyle: 'none', padding: 0 }}>
            <li>
              <a href="contact-us" style={{ fontSize: '20px', color: '#ffffff', fontWeight: 500, textDecoration: 'none' }}>
                Contact Us
              </a>
            </li>
            <li>
              <a
                style={{ fontSize: '20px', color: '#ffffff', fontWeight: 500, textDecoration: 'none', cursor: 'pointer' }}
                onClick={() => window.open(brochurePDF, '_blank')}
              >
                Download Brochure
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column" style={{ width: '25%', marginBottom: '30px' }}>
          <h4 style={{ fontSize: '24px', color: '#ffffff', textTransform: 'uppercase', marginBottom: '15px' }}>Follow Us</h4>
          <div className="social-links" style={{ textAlign: 'center' }}>
            <a
              href="#"
              style={{
                fontSize: '20px',
                color: '#ffffff',
                margin: '0 10px 10px 0',
                display: 'inline-block',
                width: '40px',
                height: '40px',
                lineHeight: '40px',
                textAlign: 'center',
                borderRadius: '50%',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              }}
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="#"
              style={{
                fontSize: '20px',
                color: '#ffffff',
                margin: '0 10px 10px 0',
                display: 'inline-block',
                width: '40px',
                height: '40px',
                lineHeight: '40px',
                textAlign: 'center',
                borderRadius: '50%',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              }}
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href="#"
              style={{
                fontSize: '20px',
                color: '#ffffff',
                margin: '0 10px 10px 0',
                display: 'inline-block',
                width: '40px',
                height: '40px',
                lineHeight: '40px',
                textAlign: 'center',
                borderRadius: '50%',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              }}
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="#"
              style={{
                fontSize: '20px',
                color: '#ffffff',
                margin: '0 10px 10px 0',
                display: 'inline-block',
                width: '40px',
                height: '40px',
                lineHeight: '40px',
                textAlign: 'center',
                borderRadius: '50%',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              }}
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </div>
        </div>
        <div className="footer-column" style={{ width: '25%', marginBottom: '30px' }}>
          <h4 style={{ fontSize: '24px', color: '#ffffff', textTransform: 'uppercase', marginBottom: '15px' }}>Certifications</h4>
          <div style={{ textAlign: 'center' }}>
            <img src={apeda} alt="ISO Logo" style={{ maxWidth: '70px', margin: '0 auto' }} />
            <img src={spiceboard} alt="ISO Logo" style={{ maxWidth: '72px', margin: '0 auto' }} />
            <img src={ISO} alt="ISO Logo" style={{ maxWidth: '88px', margin: '0 auto' }} />
            <img src={haccp} alt="ISO Logo" style={{ maxWidth: '70px', margin: '0 auto' }} />
            <img src={gmp} alt="ISO Logo" style={{ maxWidth: '67px', margin: '0 auto' }} />
            <img src={isoLogo} alt="ISO Logo" style={{ maxWidth: '70px', margin: '0 auto' }} />
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <p className="footer-text" style={{ fontSize: '25px', color: '#ffffff', lineHeight: '1.5', fontWeight: 500 }}>
          © 2023 Husk'n'Spices. All Rights Reserved <br /> Design & Developed by EDGE INFINIUM PVT. LTD.
        </p>
      </div>
    </footer>
  );
}
