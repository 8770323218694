import React from 'react';
import Header from '../components/Cattle-feed/Header';
import ListItem from '../components/ListItem';
import { data } from '../HorseData/ProductsForHorses';
import Footer from '../components/Cattle-feed/Footer';

export default function OrganicFertilizer() {
  console.log(data.filter(i => i.type === 'organic-fertilizer'));
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      {data
        .filter(i => i.type === 'organic-fertilizer')
        .map(horse => {
          return (
            <ListItem
              name={horse.name || 'No Name'}
              description={horse.description || 'No Description'}
              ingredientValues={horse.ingredientValues || 'No Ingredient Values'}
              image={horse.image}
              id={horse.id}
            />
          );
        })}
      <Footer />
    </div>
  );
}
