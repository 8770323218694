export const data = [
  {
    id: 'lucerne-Powder',
    type: 'VegetableGrainPowder',
    name: 'lucerne Powder',
    image: '../assets/images/products/husknspice/lucerne-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Maize-Flour-Powder',
    type: 'VegetableGrainPowder',
    name: 'Maize Flour Powder',
    image: '../assets/images/products/husknspice/Maize-Flour-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Guar-Meal-Churi',
    type: 'VegetableGrainPowder',
    name: 'Guar Meal Churi',
    image: '../assets/images/products/husknspice/Guar-Meal-Churi.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Sweet-Potato-Powder',
    type: 'VegetableGrainPowder',
    name: 'Sweet Potato Powder',
    image: '../assets/images/products/husknspice/Sweet-Potato-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Pumpkin-Powder',
    type: 'VegetableGrainPowder',
    name: 'Pumpkin Powder',
    image: '../assets/images/products/husknspice/Pumpkin-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Parsnip-Powder',
    type: 'VegetableGrainPowder',
    name: 'Parsnip Powder',
    image: '../assets/images/products/husknspice/parsnip-powder-removebg-preview.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Celery-Powder',
    type: 'VegetableGrainPowder',
    name: 'Celery Powder',
    image: '../assets/images/products/husknspice/Celery-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Banana-Peel-Powder',
    type: 'VegetableGrainPowder',
    name: 'Banana Peel Powder',
    image: '../assets/images/products/husknspice/banana-peel-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Watercress-Powder',
    type: 'VegetableGrainPowder',
    name: 'Watercress Powder',
    image: '../assets/images/products/husknspice/Watercress-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Potat-Powder',
    type: 'VegetableGrainPowder',
    name: 'Potato Powder',
    image: '../assets/images/products/husknspice/Potato-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Mustard-De-Oil-cake',
    type: 'VegetableGrainPowder',
    name: 'Mustard De Oil cake',
    image: '../assets/images/products/husknspice/Mustard-De-Oil-cake(2).jpg ',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Jerusalem-Artichokes-Roots-Powder',
    type: 'VegetableGrainPowder',
    name: 'Jerusalem Artichokes Roots Powder',
    image: '../assets/images/products/husknspice/Jerusalem_Artichokes_Roots_Powder-removebg-preview.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Cotton-Seeds-De-Oil-Cake',
    type: 'VegetableGrainPowder',
    name: 'Cotton Seeds De Oil Cake',
    image: '../assets/images/products/husknspice/Cotton-Seeds-De-Oil-Cake.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Roasted-Guar-Korma',
    type: 'VegetableGrainPowder',
    name: 'Roasted Guar Korma',
    image: '../assets/images/products/husknspice/Roasted-Guar-korma.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Gram-Flour-Powder',
    type: 'VegetableGrainPowder',
    name: 'Gram Flour Powder',
    image: '../assets/images/products/husknspice/Gram-Flour-Powder1.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Orange-Peel-Powder',
    type: 'PeelPowder',
    name: 'Orange Peel Powder',
    image: '../assets/images/products/husknspice/orange-peel-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Lychee-Peel-Powder',
    type: 'PeelPowder',
    name: 'Lychee Peel Powder',
    image: '../assets/images/products/husknspice/Lychee-PeelPowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Papaya-Peel-Powder',
    type: 'PeelPowder',
    name: 'Papaya Peel Powder',
    image: '../assets/images/products/husknspice/papaya-peel-powder.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Pomegranate-Peel-Powder',
    type: 'PeelPowder',
    name: 'Pomegranate Peel Powder',
    image: '../assets/images/products/husknspice/pomegranate-peel-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'WaterMelon-Peel-Powder',
    type: 'PeelPowder',
    name: 'WaterMelon Peel Powder',
    image: '../assets/images/products/husknspice/watermelon-peel-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Date-Seed-Powder',
    type: 'PeelPowder',
    name: 'Date Seed Powder',
    image: '../assets/images/products/husknspice/Date-seed-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Mango-Peel-Powder',
    type: 'PeelPowder',
    name: 'Mango Peel Powder',
    image: '../assets/images/products/husknspice/Mango-Peel-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Herbel-Lemon-Peel-Powder',
    type: 'PeelPowder',
    name: 'Herbel Lemon Peel Powder',
    image: '../assets/images/products/husknspice/Herbel-LemonPeel-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Ginger-Flakes',
    type: 'PeelPowder',
    name: 'Ginger Flakes',
    image: '../assets/images/products/husknspice/Ginger-Flakes.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Apple-Peel-Powder',
    type: 'PeelPowder',
    name: 'Apple Peel Powder',
    image: '../assets/images/products/husknspice/Apple-Peel-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Banana-Peel-Powder',
    type: 'PeelPowder',
    name: 'Banana Peel Powder',
    image: '../assets/images/products/horses/banana-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Onion-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Onion Powder',
    image: '../assets/images/products/husknspice/Onion-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spinach-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Spinach Powder',
    image: '../assets/images/products/husknspice/Spinach-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },

  {
    id: 'dehydrated-vegetable-powder',
    type: 'DehydratedVegetablePowder',
    name: 'Sweet Potato Powder',
    image: '../assets/images/products/husknspice/Sweet-Potato-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Watercress-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Watercress Powder',
    image: '../assets/images/products/husknspice/Watercress-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Carrot-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Dehydrated Carrot Powder',
    image: '../assets/images/products/husknspice/Dehydrated-CarrotPowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Beetroot-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Beetroot Powder',
    image: '../assets/images/products/husknspice/Beetroot-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Drumstick-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Drumstick Powder',
    image: '../assets/images/products/husknspice/Drumstick-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Okra-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Dehydrated Okra Powder',
    image: '../assets/images/products/husknspice/Dehydrated-OkraPowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Parsnip-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Parsnip Powder',
    image: '../assets/images/products/husknspice/parsnip-powder-removebg-preview.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Bitter-Gourd-Flakes',
    type: 'DehydratedVegetablePowder',
    name: 'Bitter Gourd Flakes',
    image: '../assets/images/products/husknspice/Bitter-Gourd-Flakes.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Tomato-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Tomato Powder',
    image: '../assets/images/products/husknspice/Tomato-Powder.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Celery-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Celery Powder',
    image: '../assets/images/products/husknspice/Celery-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Green-Chilli-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Green Chilli Powder',
    image: '../assets/images/products/husknspice/green-chilli-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Ginger-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Ginger Powder',
    image: '../assets/images/products/husknspice/Ginger-Powder.jpeg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Garlic-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Garlic Powder',
    image: '../assets/images/products/husknspice/Garlic-Powder.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Maringa-Leaf-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Maringa Leaf Powder',
    image: '../assets/images/products/husknspice/Maringa-LeafPowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Pumpkin-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Pumpkin Powder',
    image: '../assets/images/products/husknspice/Pumpkin-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Amchur-(Mengo)-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Amchur (Mengo) Powder',
    image: '../assets/images/products/husknspice/Amchur-(Mengo)Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Jerusalem-Artichokes-Roots-Powder',
    type: 'DehydratedVegetablePowder',
    name: 'Jerusalem Artichokes Roots Powder',
    image: '../assets/images/products/husknspice/Jerusalem_Artichokes_Roots_Powder-removebg-preview.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Sugarcane-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Spray Dried Sugarcane Powder',
    image: '../assets/images/products/husknspice/spray-driendsugarcane-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Pomegranate-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Spray Dried Pomegranate Powder',
    image: '../assets/images/products/husknspice/Spray-Dried-PomegranatePowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Chikoo-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Spray Dried Chikoo Powder',
    image: '../assets/images/products/husknspice/Spray-Dried-ChikooPowder.jpeg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Watermelon-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Spray Dried Watermelon Powder',
    image: '../assets/images/products/husknspice/watermelon-peel-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Guava-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Spray Dried Guava Powder',
    image: '../assets/images/products/husknspice/spray-dried-guava-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Custard-Apple-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Custard Apple Powder',
    image: '../assets/images/products/husknspice/Apple-Peel-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Orange-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Spray Dried Orange Powder',
    image: '../assets/images/products/husknspice/orange-peel-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Apple-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Spray Dried Apple Powder',
    image: '../assets/images/products/husknspice/Spray-Dried-ApplePowder.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },

  {
    id: 'Spray-Dried-Jamun-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Spray Dried Jamun Powder',
    image: '../assets/images/products/husknspice/spray-dried-jamun-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },

  {
    id: 'Spray-Dried-Coconut-Water-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Spray Dried Coconut Water Powder',
    image: '../assets/images/products/husknspice/Spray-Dried-Coconut-Waterpowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Mango-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Spray Dried Mango Powder',
    image: '../assets/images/products/husknspice/Mango-Peel-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Strawberry-Powder',
    type: 'SprayDrayedFruitsPowder',
    name: 'Spray Dried Strawberry Powder',
    image: '../assets/images/products/husknspice/spray-dried-strawberry-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },

  {
    id: 'Avocado-Powder',
    type: 'HerbalProducts',
    name: 'Avocado Powder',
    image: '../assets/images/products/husknspice/avocado-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },

  {
    id: 'Giloy-Powder',
    type: 'HerbalProducts',
    name: 'Giloy Powder',
    image: '../assets/images/products/husknspice/Giloy-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Calcium-Sennosides-Extracts',
    type: 'HerbalProducts',
    name: 'Calcium Sennosides Extracts',
    image: '../assets/images/products/husknspice/Calcium-Sennosides.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Papaya-Leaf-Powder',
    type: 'HerbalProducts',
    name: 'Papaya Leaf Powder',
    image: '../assets/images/products/husknspice/papaya-leaves-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Moringa-Leaf-Powder',
    type: 'HerbalProducts',
    name: 'Moringa Leaf Powder',
    image: '../assets/images/products/husknspice/Moringa-LeaPowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Srasparilla-(Anantamul)',
    type: 'HerbalProducts',
    name: 'Srasparilla (Anantamul)',
    image: '../assets/images/products/husknspice/Anantmool-1.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Ashwagandha-Powder',
    type: 'HerbalProducts',
    name: 'Ashwagandha Powder',
    image: '../assets/images/products/husknspice/Ashwagandha-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Amla-Powder',
    type: 'HerbalProducts',
    name: 'Amla Powder',
    image: '../assets/images/products/husknspice/Amla-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Areetha-Powder',
    type: 'HerbalProducts',
    name: 'Areetha Powder',
    image: '../assets/images/products/husknspice/Areetha-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Gymnema-Powder',
    type: 'HerbalProducts',
    name: 'Gymnema Powder',
    image: '../assets/images/products/husknspice/Gymnema.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Stevia-Powder',
    type: 'HerbalProducts',
    name: 'Stevia Powder',
    image: '../assets/images/products/husknspice/Stevia-Powder.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Shatawari-Powder',
    type: 'HerbalProducts',
    name: 'Shatawari Powder',
    image: '../assets/images/products/husknspice/Shatawari-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Basil-Powder-(Tulsi)',
    type: 'HerbalProducts',
    name: 'Basil Powder (Tulsi)',
    image: '../assets/images/products/husknspice/Basil-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Senna-Leaves-Powder',
    type: 'HerbalProducts',
    name: 'Senna Leaves Powder',
    image: '../assets/images/products/husknspice/Senna-Leaves.jpeg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Aloe-vera-Powder',
    type: 'HerbalProducts',
    name: 'Aloe vera Powder',
    image: '../assets/images/products/husknspice/Aloevera-Powder.jpeg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Mint-Powder',
    type: 'HerbalProducts',
    name: 'Mint Powder',
    image: '../assets/images/products/husknspice/Mint-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Fennel-Saumph',
    type: 'Spice',
    name: 'Fennel/Saumph',
    image: '../assets/images/products/husknspice/FennelSaumph.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Caraway-Seeds-Kala-Jeera',
    type: 'Spice',
    name: 'Caraway Seeds /Kala Jeera',
    image: '../assets/images/products/husknspice/Caraway-Seeds.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Black-Pepper-Gul-Mohor',
    type: 'Spice',
    name: 'Black Pepper/ Gul-Mohor',
    image: '../assets/images/products/husknspice/Black-Pepper.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dill-Soa',
    type: 'Spice',
    name: 'Dill/Soa',
    image: '../assets/images/products/husknspice/DillSoa.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Clove-Long',
    type: 'Spice',
    name: 'Clove/Long',
    image: '../assets/images/products/husknspice/CloveLong.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Bay-Leaf-Tej-Patta',
    type: 'Spice',
    name: 'Bay Leaf/Tej Patta',
    image: '../assets/images/products/husknspice/BayLeaf-Tejpatta.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Curry-Leaves',
    type: 'Spice',
    name: 'Curry Leaves',
    image: '../assets/images/products/husknspice/Curry-Leaves.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Turmeric-Haldi',
    type: 'Spice',
    name: 'Turmeric/Haldi',
    image: '../assets/images/products/husknspice/Turmeric-Haldi.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Cinnamon-Dal-Chini',
    type: 'Spice',
    name: 'Cinnamon/Dal-Chini',
    image: '../assets/images/products/husknspice/CinnamonDal-Chini.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Asafoetida-Hing',
    type: 'Spice',
    name: 'Asafoetida/Hing',
    image: '../assets/images/products/husknspice/Asafoetida-Hing.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Mace-Gada',
    type: 'Spice',
    name: 'Mace/Gada',
    image: '../assets/images/products/husknspice/MaceGada.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Cumin-Jeera',
    type: 'Spice',
    name: 'Cumin/Jeera',
    image: '../assets/images/products/husknspice/uminJeera.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Star-Anise-Chakra-Phool',
    type: 'Spice',
    name: 'Star Anise /Chakra Phool',
    image: '../assets/images/products/husknspice/Star-Anise.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Fenugreek-Methi',
    type: 'Spice',
    name: 'Fenugreek/Methi',
    image: '../assets/images/products/husknspice/Fenugreek-Methi.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Nutmeg-Jayaphal',
    type: 'Spice',
    name: 'Nutmeg/Jayaphal',
    image: '../assets/images/products/husknspice/Nutmeg-Jayaphal.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Nigella-Seeds-Kalonji',
    type: 'Spice',
    name: 'Nigella Seeds /Kalonji',
    image: '../assets/images/products/husknspice/Nigella-Seeds.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Mint-Pudina',
    type: 'Spice',
    name: 'Mint/Pudina',
    image: '../assets/images/products/husknspice/Mint-Pudina.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Cardamom-Elaichi',
    type: 'Spice',
    name: 'Cardamom/Elaichi',
    image: '../assets/images/products/husknspice/Cardamom-Elaichi.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Carom-Ajwain',
    type: 'Spice',
    name: 'Carom/Ajwain',
    image: '../assets/images/products/husknspice/Carom0-Ajwain.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Mustard-Seeds-Sarso',
    type: 'Spice',
    name: 'Mustard Seeds /Sarso',
    image: '../assets/images/products/husknspice/Mustard-Seeds.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Apple-Powder',
    type: 'DehydratedFruitsPowder',
    name: 'Apple Powder',
    image: '../assets/images/products/husknspice/Apple-Peel-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Date-Powder',
    type: 'DehydratedFruitsPowder',
    name: 'Date Powder',
    image: '../assets/images/products/husknspice/Date-seed-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Guava-Powder',
    type: 'DehydratedFruitsPowder',
    name: 'Dehydrated Guava Powder',
    image: '../assets/images/products/husknspice/spray-dried-guava-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-banana-Powder',
    type: 'DehydratedFruitsPowder',
    name: 'Dehydrated Banana Powder',
    image: '../assets/images/products/horses/banana-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Mango-Powder',
    type: 'DehydratedFruitsPowder',
    name: 'Mango Powder',
    image: '../assets/images/products/husknspice/Mango-Peel-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Pineapple-Powder',
    type: 'DehydratedFruitsPowder',
    name: 'Dehydrated Pineapple Powder',
    image: '../assets/images/products/husknspice/Dehydrated-Pineapple-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Papaya-Powder',
    type: 'DehydratedFruitsPowder',
    name: 'Dehydrated Papaya Powder',
    image: '../assets/images/products/husknspice/papaya-peel-powder.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Spinach-Powder',
    type: 'SprayDrayedVegetablePowder',
    name: 'Spray Dried Spinach Powder',
    image: '../assets/images/products/husknspice/Spinach-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Beetroot-Powder',
    type: 'SprayDrayedVegetablePowder',
    name: 'Spray Dried Beetroot Powder',
    image: '../assets/images/products/husknspice/Beetroot-Powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Green-Chilli-Powder',
    type: 'SprayDrayedVegetablePowder',
    name: 'Spray Dried Green Chilli Powder',
    image: '../assets/images/products/husknspice/green-chilli-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Carrot-Powder',
    type: 'SprayDrayedVegetablePowder',
    name: 'Spray Dried Carrot Powder',
    image: '../assets/images/products/husknspice/spraydriend-carrotpowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Ginger-Powder',
    type: 'SprayDrayedVegetablePowder',
    name: 'Spray Dried Ginger Powder',
    image: '../assets/images/products/husknspice/SprayDried-GingerPowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Lemon-Powder',
    type: 'SprayDrayedVegetablePowder',
    name: 'Spray Dried Lemon Powder',
    image: '../assets/images/products/husknspice/SprayDried-lemonPowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Pumpkin-Powder',
    type: 'SprayDrayedVegetablePowder',
    name: 'Spray Dried Pumpkin Powder',
    image: '../assets/images/products/husknspice/SprayDried-pumpkinPowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Spray-Dried-Tamarind-Powder',
    type: 'SprayDrayedVegetablePowder',
    name: 'Spray Dried Tamarind Powder',
    image: '../assets/images/products/husknspice/SprayDried-tamarindPowder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Tomato-Powder',
    type: 'SprayDrayedVegetablePowder',
    name: 'Tomato Powder',
    image: '../assets/images/products/husknspice/Tomato-Powder.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Carrot-Flakes',
    type: 'DehydratedVegetableFlakesAndCube',
    name: 'Carrot Flakes',
    image: '../assets/images/products/husknspice/Carrot-Flakes.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Ginger-Flakes',
    type: 'DehydratedVegetableFlakesAndCube',
    name: 'Ginger Flakes',
    image: '../assets/images/products/husknspice/Ginger-Flakes.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Bitter-Gourd-Flakes',
    type: 'DehydratedVegetableFlakesAndCube',
    name: 'Bitter Gourd Flakes',
    image: '../assets/images/products/husknspice/Bitter-Gourd-Flakes.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Curry-Leaves',
    type: 'DehydratedVegetableFlakesAndCube',
    name: 'Dehydrated Curry Leaves',
    image: '../assets/images/products/husknspice/Dehydrated-Curryleaves.jpeg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Garlic-Flakes',
    type: 'DehydratedVegetableFlakesAndCube',
    name: 'Garlic Flakes',
    image: '../assets/images/products/husknspice/Garlic-Flakes.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Black-Lemon',
    type: 'DehydratedVegetableFlakesAndCube',
    name: 'Black Lemon',
    image: '../assets/images/products/husknspice/Black-Lemon.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Okra-(lady-Finger)-Flakes',
    type: 'DehydratedVegetableFlakesAndCube',
    name: 'Okra (lady Finger) Flakes',
    image: '../assets/images/products/husknspice/Okra(lady-Finger)Flakes.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Green-Chilli-Flakes',
    type: 'DehydratedVegetableFlakesAndCube',
    name: 'Green Chilli Flakes',
    image: '../assets/images/products/husknspice/Green-Chilli-Flakes.png',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Potato-Flakes',
    type: 'DehydratedVegetableFlakesAndCube',
    name: 'Potato Flakes',
    image: '../assets/images/products/husknspice/Potato-Flakes.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Tomato-Flakes',
    type: 'DehydratedVegetableFlakesAndCube',
    name: 'Tomato Flakes',
    image: '../assets/images/products/husknspice/Tomato-Flakes.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Cabbage-Flakes',
    type: 'DehydratedVegetableFlakesAndCube',
    name: 'Cabbage Flakes',
    image: '../assets/images/products/husknspice/Cabbage-Flakes.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
];
