export const data = [
  {
    id: 'Dehydrated-Banana-Powder',
    type: 'horse',
    name: 'Dehydrated Banana Powder',
    image: '../assets/images/products/horses/banana-powder.jpg',
    description:
      'Dehydrated banana powder is made from ripe bananas that have been dried and ground into a fine powder. This versatile product retains the natural sweetness and flavor of bananas while offering a convenient and shelf-stable form for feeding horses.',
    benefits: [
      {
        title: 'Rich in Potassium',
        description: 'Bananas are a great source of potassium, which is essential for muscle function and overall health in horses.',
      },
      {
        title: 'Natural Energy Boost',
        description:
          'The carbohydrates in bananas provide a quick and natural source of energy, making them an ideal snack before or after exercise.',
      },
      {
        title: 'Digestive Aid',
        description: 'Bananas contain soluble fiber, which can help regulate digestion and support gastrointestinal health in horses.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Dehydrated banana powder contains various vitamins and minerals, including Vitamin C, Vitamin B6, and manganese, which contribute to overall health and well-being.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix banana powder with other feeds or supplements to provide an extra boost of energy during strenuous activities or training sessions.',
      },
      {
        title: 'Palatability Enhancer',
        description:
          'The sweet flavor of banana powder can help mask the taste of less palatable feeds or medications, encouraging horses to eat more willingly.',
      },
      {
        title: 'Digestive Support',
        description: 'Incorporate banana powder into the diet to promote healthy digestion and alleviate occasional gastrointestinal issues.',
      },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the sweet and fruity flavor of dehydrated banana powder, making it a popular addition to their diet. Whether mixed with other feeds or offered as a standalone treat, banana powder is sure to be a hit among horses, providing both nutrition and enjoyment.',
    },
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated banana powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated banana powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated banana powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated banana powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated banana powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated banana powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dry-Dates-Powder',
    type: 'horse',
    name: 'Dry Dates Powder',
    image: '../assets/images/products/horses/dry-dates-powder.webp',
    description:
      "Dry dates powder is a finely ground form of dehydrated dates, created by grinding dried dates into a smooth powder. Dates are naturally sweet and rich in nutrients, and the powder form offers a convenient way to incorporate their benefits into a horse's diet.",
    benefits: [
      {
        title: 'Natural Sweetness',
        description: 'Dry dates powder provides a natural source of sweetness, making it a palatable addition to feeds and supplements for horses.',
      },
      {
        title: 'Energy Boost',
        description:
          'Dates are rich in carbohydrates, which serve as a quick and sustainable source of energy for horses during demanding activities or training sessions.',
      },
      {
        title: 'Nutrient-Rich',
        description:
          'Dates contain various vitamins and minerals, including potassium, magnesium, and Vitamin B6, which support overall health and well-being in horses.',
      },
      {
        title: 'Digestive Health',
        description: 'The fiber content in dates powder can help promote healthy digestion and regulate bowel movements in horses.',
      },
    ],
    uses: [
      {
        title: 'Energy Supplement',
        description:
          'Mix dry dates powder with other feeds or supplements to provide an extra energy boost for horses during periods of increased activity or performance.',
      },
      {
        title: 'Flavor Enhancer',
        description: 'The natural sweetness of dates powder can enhance the palatability of feeds, encouraging horses to eat more readily.',
      },
      {
        title: 'Digestive Support',
        description:
          'Incorporate dates powder into the diet to support healthy digestion and provide relief from occasional gastrointestinal discomfort.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses typically enjoy the sweet and flavorful taste of dry dates powder, making it an appealing addition to their diet. Whether mixed into their regular feed or offered as a standalone treat, dates powder provides both nutritional benefits and a tasty indulgence for horses.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '2-5%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '3200-3500 kcal/kg' },
      { title: 'Calcium', value: '0.1-0.5%' },
      { title: 'Phosphorus', value: '0.1-0.4%' },
      { title: 'Vitamin A', value: '1000-2000 IU/kg' },
      { title: 'Vitamin C', value: '500-800 mg/kg' },
      { title: 'Crude Protein', value: '1.5-4.0%' },
      { title: 'Crude Fiber', value: '8.0-12.0%' },
      { title: 'Fat', value: '1.0-3.0%' },
      { title: 'Moisture', value: '3.0-6.0%' },
      { title: 'Ash', value: '2.0-4.0%' },
      { title: 'Fiber', value: '8.0-12.0%' },
      { title: 'Energy', value: '3.2-3.5 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '80-90%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated date powder gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description: 'Mix the dehydrated date powder with other horse feed, such as grains or pellets, or sprinkle it directly onto hay or grass.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated date powder as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated date powder.',
      },
      {
        title: 'Incorporation',
        description:
          'Mix the dehydrated date powder thoroughly with other feed to enhance flavor and aroma, or provide it separately as standalone treats.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated date powder to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },

  {
    id: 'Dehydrated-Black-Chickpeas',
    type: 'horse',
    name: 'Dehydrated Black Chickpeas',
    image: '../assets/images/products/horses/dry-black-chickpeas.webp',
    description:
      'Black chickpeas, also known as kala chana, are a type of legume characterized by their small size and dark color. These nutritious legumes are a staple in many cuisines and are commonly consumed in various forms, including boiled, roasted, or ground into flour.',
    benefits: [
      {
        title: 'Protein-Rich',
        description:
          'Black chickpeas are an excellent source of plant-based protein, providing essential amino acids necessary for muscle development and repair in horses.',
      },
      {
        title: 'Fiber Content',
        description:
          'Rich in dietary fiber, black chickpeas promote healthy digestion and help regulate bowel movements in horses, contributing to overall gastrointestinal health.',
      },
      {
        title: 'Vitamins and Minerals',
        description:
          'Black chickpeas contain a range of vitamins and minerals, including iron, magnesium, and Vitamin B6, which support various bodily functions and contribute to overall well-being in horses.',
      },
      {
        title: 'Energy Source',
        description:
          'The carbohydrates in black chickpeas provide horses with a sustained source of energy, making them suitable for inclusion in the diet during periods of increased activity or performance.',
      },
    ],
    uses: [
      {
        title: 'Feed Supplement',
        description:
          "Incorporate boiled or ground black chickpeas into the horse's feed to boost protein intake and provide an additional source of energy.",
      },
      {
        title: 'Treats',
        description:
          'Roasted black chickpeas can be offered as a healthy and nutritious treat for horses, providing a satisfying crunch and flavor variation in their diet.',
      },
      {
        title: 'Meal Addition',
        description:
          'Mix cooked black chickpeas with other grains or vegetables to create a balanced and flavorful meal for horses, enhancing both nutrition and palatability.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses generally enjoy the taste and texture of black chickpeas, whether served boiled, roasted, or ground into flour. As a versatile ingredient, black chickpeas can be prepared in various ways to cater to different preferences, providing horses with a nutritious and enjoyable addition to their diet.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '20-25%' },
      { title: 'Fiber', value: '25-30%' },
      { title: 'Energy (ME)', value: '3000-3200 kcal/kg' },
      { title: 'Calcium', value: '2.0-2.5%' },
      { title: 'Phosphorus', value: '1.0-1.5%' },
      { title: 'Vitamin A', value: '10000-12000 IU/kg' },
      { title: 'Vitamin C', value: '1200-1500 mg/kg' },
      { title: 'Crude Protein', value: '18.0-22.0%' },
      { title: 'Crude Fiber', value: '15.0-18.0%' },
      { title: 'Fat', value: '2.0-3.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '4.0-6.0%' },
      { title: 'Fiber', value: '15.0-18.0%' },
      { title: 'Energy', value: '2.8-3.2 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '60-70%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated black chickpeas gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description:
          "Begin with small amounts, approximately one to two tablespoons per serving, and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description:
          'Soak the dehydrated black chickpeas in water overnight to rehydrate them before feeding. Drain excess water before offering to your horse.',
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated black chickpeas as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated black chickpeas.',
      },
      {
        title: 'Incorporation',
        description: 'Mix the rehydrated black chickpeas with other feed, such as grains or pellets, or sprinkle them directly onto hay or grass.',
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated black chickpeas to ensure they chew them thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Carrots',
    type: 'horse',
    name: 'Dehydrated Carrots (Gajar)',
    image: '../assets/images/products/horses/dehydrated-carrot-powder.webp',
    description:
      'Dehydrated carrots are a beloved treat among horses, retaining their natural sweetness and crunchiness through the dehydration process. This method preserves the vibrant orange color and enhances the nutritional profile of the carrots, making them an excellent choice for a healthy and delicious snack.',
    benefits: [
      {
        title: 'Rich in Vitamin A',
        description: 'Essential for maintaining healthy vision, immune function, and skin health.',
      },
      {
        title: 'Low in Calories',
        description: 'Ideal for regular feeding without adding extra calories.',
      },
      {
        title: 'Good for Dental Health',
        description: 'The crunchy texture helps in keeping teeth clean and reducing plaque build-up.',
      },
      {
        title: 'High in Fiber',
        description: 'Aids in proper digestion and prevents constipation.',
      },
    ],
    uses: [
      {
        title: 'Training Aid',
        description: 'Perfect for use as a reward during training sessions.',
      },
      {
        title: 'Diet Supplement',
        description: "Adds variety and essential vitamins to the horse's diet.",
      },
      {
        title: 'Snack',
        description: 'Can be given as a healthy and crunchy snack any time of the day.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses find the natural sweetness and crunchiness of dehydrated carrots irresistible, making them a favorite treat for both nutrition and taste. Their appealing flavor and texture make them highly enjoyable for horses.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '7-10%' },
      { title: 'Fiber', value: '15-20%' },
      { title: 'Energy (ME)', value: '3000-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '600-800 mg/kg' },
      { title: 'Crude Protein', value: '6.0-8.0%' },
      { title: 'Crude Fiber', value: '5.0-7.0%' },
      { title: 'Fat', value: '1.0-2.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '4.0-6.0%' },
      { title: 'Fiber', value: '5.0-7.0%' },
      { title: 'Energy', value: '3.0-3.2 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '65-75%' },
    ],
    feedingInstruction: [
      {
        title: 'Quantity',
        description:
          "Offer dehydrated carrots as a supplement or treat in moderation. Start with small amounts and gradually increase based on your horse's response.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated carrots by soaking them in water for a few hours or overnight, or feed them dry depending on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: 'Provide dehydrated carrots as a supplement or treat a few times a week, rather than daily, to maintain dietary balance.',
      },
      {
        title: 'Monitoring',
        description: "Monitor your horse's reaction to the dehydrated carrots, including any changes in appetite, stool consistency, or behavior.",
      },
      {
        title: 'Incorporation',
        description: "Mix rehydrated carrots into your horse's regular feed as a flavorful addition, or offer them separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description:
          "Offer small portions of dehydrated carrots initially, such as a handful or a few slices, and adjust based on your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Supervise your horse while they consume the dehydrated carrots to ensure they chew them thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Pumpkin',
    type: 'horse',
    name: 'Dehydrated Pumpkin (Kaddu)',
    image: '../assets/images/products/horses/dehydrated-pumpkin.jpg',

    description:
      'Dehydrated pumpkin offers a sweet and soft treat that is both nutritious and palatable. The dehydration process concentrates its natural sugars, making it even more appealing to horses. It is rich in vitamins and provides essential nutrients for overall health.',
    benefits: [
      {
        title: 'Rich in Vitamins A and C',
        description: 'Supports immune function, skin health, and vision.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents digestive issues.',
      },
      {
        title: 'Hydrating',
        description: 'Contains a high water content, aiding in hydration.',
      },
      {
        title: 'Low in Fat',
        description: 'Suitable for horses that require low-fat diets.',
      },
    ],
    uses: [
      {
        title: 'Seasonal Treat',
        description: 'Perfect for adding seasonal variety, especially in the fall.',
      },
      {
        title: 'Digestive Aid',
        description: 'The high fiber content can help with digestive issues and promote gut health.',
      },
      {
        title: 'Diet Supplement',
        description: 'Adds essential nutrients and variety to the diet.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses find the sweet and soft texture of dehydrated pumpkin delicious, making it a favored treat. Its taste and texture make it enjoyable and beneficial for their diet.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '6-8%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '2800-3000 kcal/kg' },
      { title: 'Calcium', value: '0.5-0.8%' },
      { title: 'Phosphorus', value: '0.2-0.5%' },
      { title: 'Vitamin A', value: '4000-6000 IU/kg' },
      { title: 'Vitamin C', value: '500-700 mg/kg' },
      { title: 'Crude Protein', value: '5.0-7.0%' },
      { title: 'Crude Fiber', value: '6.0-8.0%' },
      { title: 'Fat', value: '1.0-2.0%' },
      { title: 'Moisture', value: '4.0-7.0%' },
      { title: 'Ash', value: '3.0-5.0%' },
      { title: 'Fiber', value: '6.0-8.0%' },
      { title: 'Energy', value: '2.8-3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '60-70%' },
    ],
    feedingInstruction: [
      {
        title: 'Quantity',
        description:
          "Offer dehydrated pumpkin as a supplement or treat in moderation. Start with small amounts and gradually increase based on your horse's response.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated pumpkin by soaking it in water for a few hours or overnight, or feed it dry depending on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: 'Provide dehydrated pumpkin as a supplement or treat a few times a week, rather than daily, to maintain dietary balance.',
      },
      {
        title: 'Monitoring',
        description: "Monitor your horse's reaction to the dehydrated pumpkin, including any changes in appetite, stool consistency, or behavior.",
      },
      {
        title: 'Incorporation',
        description: "Mix rehydrated pumpkin into your horse's regular feed as a flavorful addition, or offer it separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description:
          "Offer small portions of dehydrated pumpkin initially, such as a handful or a few slices, and adjust based on your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description: 'Supervise your horse while they consume the dehydrated pumpkin to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Cabbage',
    type: 'horse',
    name: 'Dehydrated Cabbage (Patta Gobi)',
    image: '../assets/images/products/horses/dried-cabbage-flakes.webp',
    description:
      'Dehydrated cabbage is crunchy and nutritious, offering a healthy treat for horses when fed sparingly. It is packed with vitamins and minerals that support overall health. The dehydration process enhances its crunchiness, making it an appealing snack.',
    benefits: [
      {
        title: 'Rich in Vitamins C and K',
        description: 'Supports immune function and bone health.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents constipation.',
      },
      {
        title: 'Low in Calories',
        description: 'Suitable for regular feeding without adding extra calories.',
      },
      {
        title: 'Antioxidants',
        description: 'Contains compounds that help protect cells from damage.',
      },
    ],
    uses: [
      {
        title: 'Occasional Treat',
        description: 'Should be given sparingly to prevent gas and digestive discomfort.',
      },
      {
        title: 'Dietary Supplement',
        description: "Adds essential nutrients and variety to the horse's diet.",
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses appreciate the crunchy texture of dehydrated cabbage, making it a satisfying treat when fed in moderation. Its taste and crunchiness make it enjoyable for horses, providing both nutrition and variety.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '25-30%' },
      { title: 'Energy (ME)', value: '2800-3000 kcal/kg' },
      { title: 'Calcium', value: '1.0-1.5%' },
      { title: 'Phosphorus', value: '0.4-0.7%' },
      { title: 'Vitamin A', value: '4000-6000 IU/kg' },
      { title: 'Vitamin C', value: '700-1000 mg/kg' },
      { title: 'Crude Protein', value: '7.0-9.0%' },
      { title: 'Crude Fiber', value: '6.0-8.0%' },
      { title: 'Fat', value: '1.0-2.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '3.0-5.0%' },
      { title: 'Fiber', value: '6.0-8.0%' },
      { title: 'Energy', value: '2.8-3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '70-80%' },
    ],
    feedingInstruction: [
      {
        title: 'Quantity',
        description:
          "Offer dehydrated cabbage as a supplement or treat in moderation. Start with small amounts and gradually increase based on your horse's response.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated cabbage by soaking it in water for a few hours or overnight, or feed it dry depending on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: 'Provide dehydrated cabbage as a supplement or treat a few times a week, rather than daily, to maintain dietary balance.',
      },
      {
        title: 'Monitoring',
        description: "Monitor your horse's reaction to the dehydrated cabbage, including any changes in appetite, stool consistency, or behavior.",
      },
      {
        title: 'Incorporation',
        description: "Mix rehydrated cabbage into your horse's regular feed as a flavorful addition, or offer it separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description:
          "Offer small portions of dehydrated cabbage initially, such as a handful or a few slices, and adjust based on your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description: 'Supervise your horse while they consume the dehydrated cabbage to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Broccoli',
    type: 'horse',
    name: 'Dehydrated Broccoli',
    image: '../assets/images/products/horses/dehydrated-broccoli.webp',
    description:
      'Dehydrated broccoli florets are nutritious and crunchy, offering a healthy treat for horses. However, they should be given in small amounts to avoid gas and digestive discomfort. The dehydration process retains the nutrients and enhances the crunchiness of the florets.',
    benefits: [
      {
        title: 'High in Vitamins C and K',
        description: 'Supports immune function and bone health.',
      },
      {
        title: 'Antioxidants',
        description: 'Contains compounds that help protect cells from damage.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents constipation.',
      },
      {
        title: 'Low in Calories',
        description: 'Suitable for regular feeding without adding extra calories.',
      },
    ],
    uses: [
      {
        title: 'Small Treat',
        description: 'Best given in small amounts to prevent digestive issues.',
      },
      {
        title: 'Dietary Supplement',
        description: 'Adds essential nutrients and variety to the diet.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses enjoy the crunchy florets of dehydrated broccoli, appreciating the taste and texture in small amounts. Its nutritional benefits and appealing flavor make it a healthy and enjoyable treat.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '10-15%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3000 kcal/kg' },
      { title: 'Calcium', value: '1.0-1.5%' },
      { title: 'Phosphorus', value: '0.4-0.7%' },
      { title: 'Vitamin A', value: '6000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '9.0-12.0%' },
      { title: 'Crude Fiber', value: '8.0-10.0%' },
      { title: 'Fat', value: '1.5-2.5%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '4.0-6.0%' },
      { title: 'Fiber', value: '8.0-10.0%' },
      { title: 'Energy', value: '2.8-3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '70-80%' },
    ],
    feedingInstruction: [
      {
        title: 'Quantity',
        description:
          "Offer dehydrated broccoli as a supplement or treat in moderation. Start with small amounts and gradually increase based on your horse's response.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated broccoli by soaking it in water for a few hours or overnight, or feed it dry depending on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: 'Provide dehydrated broccoli as a supplement or treat a few times a week, rather than daily, to maintain dietary balance.',
      },
      {
        title: 'Monitoring',
        description: "Monitor your horse's reaction to the dehydrated broccoli, including any changes in appetite, stool consistency, or behavior.",
      },
      {
        title: 'Incorporation',
        description: "Mix rehydrated broccoli into your horse's regular feed as a flavorful addition, or offer it separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description:
          "Offer small portions of dehydrated broccoli initially, such as a handful or a few florets, and adjust based on your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description: 'Supervise your horse while they consume the dehydrated broccoli to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Fenugreek-Leaves',
    type: 'horse',
    name: 'Dehydrated Fenugreek Leaves (Methi)',
    image: '../assets/images/products/horses/kasuri-methi-dried.webp',
    description:
      "Dehydrated fenugreek leaves are nutritious greens that some horses may enjoy. Known for their medicinal properties and nutritional value, they offer a unique and beneficial addition to a horse's diet.",
    benefits: [
      {
        title: 'High in Vitamins and Minerals',
        description: 'Provides essential nutrients that support overall health.',
      },
      {
        title: 'Digestive Aid',
        description: 'Known for aiding digestion and promoting gut health.',
      },
      {
        title: 'Anti-inflammatory Properties',
        description: 'Can help reduce inflammation and improve joint health.',
      },
    ],
    uses: [
      {
        title: 'Occasional Treat',
        description: 'Can be given occasionally for nutritional benefits.',
      },
      {
        title: 'Dietary Supplement',
        description: 'Adds variety and essential nutrients to the diet.',
      },
      {
        title: 'Health Supplement',
        description: 'May be used for its medicinal properties to support overall health.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Some horses enjoy the unique taste of dehydrated fenugreek leaves, making them a healthy and interesting addition to their diet. Their nutritional benefits and distinctive flavor make them enjoyable for horses.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '20-25%' },
      { title: 'Fiber', value: '25-30%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '2.0-2.5%' },
      { title: 'Phosphorus', value: '1.0-1.5%' },
      { title: 'Vitamin A', value: '10000-12000 IU/kg' },
      { title: 'Vitamin C', value: '1200-1500 mg/kg' },
      { title: 'Crude Protein', value: '18.0-22.0%' },
      { title: 'Crude Fiber', value: '15.0-18.0%' },
      { title: 'Fat', value: '2.0-3.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '4.0-6.0%' },
      { title: 'Fiber', value: '15.0-18.0%' },
      { title: 'Energy', value: '2.8-3.2 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '60-70%' },
    ],
    feedingInstruction: [
      {
        title: 'Quantity',
        description:
          "Offer dehydrated fenugreek leaves as a supplement or treat in moderation. Start with small amounts and gradually increase based on your horse's response.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated fenugreek leaves by soaking them in water for a few hours or overnight, or feed them dry depending on your horse's preference.",
      },
      {
        title: 'Frequency',
        description:
          'Provide dehydrated fenugreek leaves as a supplement or treat a few times a week, rather than daily, to maintain dietary balance.',
      },
      {
        title: 'Monitoring',
        description:
          "Monitor your horse's reaction to the dehydrated fenugreek leaves, including any changes in appetite, stool consistency, or behavior.",
      },
      {
        title: 'Incorporation',
        description:
          "Mix rehydrated fenugreek leaves into your horse's regular feed as a flavorful addition, or offer them separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description:
          "Offer small portions of dehydrated fenugreek leaves initially, such as a handful or a few pinches, and adjust based on your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Supervise your horse while they consume the dehydrated fenugreek leaves to ensure they chew them thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Spinach',
    type: 'horse',
    name: 'Dehydrated Spinach (Palak)',
    image: '../assets/images/products/horses/green-peas.webp',
    description:
      'Dehydrated spinach is a nutritious leafy green that should be fed in moderation due to its high oxalate content, which can interfere with calcium absorption. The dehydration process preserves its nutritional value, making it a beneficial treat.',
    benefits: [
      {
        title: 'Rich in Iron and Vitamins',
        description: 'Provides essential nutrients that support overall health.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents constipation.',
      },
      {
        title: 'Antioxidants',
        description: 'Contains compounds that help protect cells from damage.',
      },
      {
        title: 'Low in Calories',
        description: 'Suitable for regular feeding without adding extra calories.',
      },
    ],
    uses: [
      {
        title: 'Moderate Treat',
        description: 'Best given in moderation to avoid nutritional imbalances.',
      },
      {
        title: 'Dietary Supplement',
        description: 'Adds essential nutrients and variety to the diet.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses appreciate the nutrient-rich profile of dehydrated spinach, enjoying it as a healthy snack when fed in moderation. Its nutritional benefits and leafy texture make it an appealing treat.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '20-25%' },
      { title: 'Fiber', value: '25-30%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '2.0-2.5%' },
      { title: 'Phosphorus', value: '1.0-1.5%' },
      { title: 'Vitamin A', value: '10000-12000 IU/kg' },
      { title: 'Vitamin C', value: '1200-1500 mg/kg' },
      { title: 'Crude Protein', value: '18.0-22.0%' },
      { title: 'Crude Fiber', value: '15.0-18.0%' },
      { title: 'Fat', value: '2.0-3.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '4.0-6.0%' },
      { title: 'Fiber', value: '15.0-18.0%' },
      { title: 'Energy', value: '2.8-3.2 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '60-70%' },
    ],
    feedingInstruction: [
      {
        title: 'Quantity',
        description:
          "Offer dehydrated spinach as a supplement or treat in moderation. Start with small amounts and gradually increase based on your horse's response.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated spinach by soaking it in water for a few hours or overnight, or feed it dry depending on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: 'Provide dehydrated spinach as a supplement or treat a few times a week, rather than daily, to maintain dietary balance.',
      },
      {
        title: 'Monitoring',
        description: "Monitor your horse's reaction to the dehydrated spinach, including any changes in appetite, stool consistency, or behavior.",
      },
      {
        title: 'Incorporation',
        description: "Mix rehydrated spinach into your horse's regular feed as a flavorful addition, or offer it separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description:
          "Offer small portions of dehydrated spinach initially, such as a handful or a few leaves, and adjust based on your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description: 'Supervise your horse while they consume the dehydrated spinach to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Cauliflower',
    type: 'horse',
    name: 'Dehydrated Cauliflower (Phool Gobi)',
    image: '../assets/images/products/horses/cauliflower-dehydrated.webp',
    description:
      'Dehydrated cauliflower is crunchy and nutritious, offering a healthy treat for horses when fed in moderation. The dehydration process preserves its nutrients and enhances its texture, making it an appealing snack for horses.',
    benefits: [
      {
        title: 'Rich in Vitamins C and K',
        description: 'Supports immune function and bone health.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents constipation.',
      },
      {
        title: 'Antioxidants',
        description: 'Contains compounds that help protect cells from damage.',
      },
      {
        title: 'Low in Calories',
        description: 'Suitable for regular feeding without adding extra calories.',
      },
    ],
    uses: [
      {
        title: 'Moderate Treat',
        description: 'Best given in moderation to prevent gas and digestive issues.',
      },
      {
        title: 'Dietary Supplement',
        description: 'Adds essential nutrients and variety to the diet.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses enjoy the crunchy texture and mild flavor of dehydrated cauliflower, making it a nutritious treat when given in moderation. Its taste and crunchiness make it enjoyable and beneficial for their diet.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3000 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '600-800 mg/kg' },
      { title: 'Crude Protein', value: '7.0-9.0%' },
      { title: 'Crude Fiber', value: '6.0-8.0%' },
      { title: 'Fat', value: '1.0-2.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '3.0-5.0%' },
      { title: 'Fiber', value: '6.0-8.0%' },
      { title: 'Energy', value: '2.8-3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '70-80%' },
    ],
    feedingInstruction: [
      {
        title: 'Quantity',
        description:
          "Offer dehydrated cauliflower as a supplement or treat in moderation. Start with small amounts and gradually increase based on your horse's response.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated cauliflower by soaking it in water for a few hours or overnight, or feed it dry depending on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: 'Provide dehydrated cauliflower as a supplement or treat a few times a week, rather than daily, to maintain dietary balance.',
      },
      {
        title: 'Monitoring',
        description:
          "Monitor your horse's reaction to the dehydrated cauliflower, including any changes in appetite, stool consistency, or behavior.",
      },
      {
        title: 'Incorporation',
        description:
          "Mix rehydrated cauliflower into your horse's regular feed as a flavorful addition, or offer it separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description:
          "Offer small portions of dehydrated cauliflower initially, such as a handful or a few slices, and adjust based on your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Supervise your horse while they consume the dehydrated cauliflower to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Beetroot',
    type: 'horse',
    name: 'Dehydrated Beetroot (Chukandar)',
    image: '../assets/images/products/horses/beet pulp.webp',

    description:
      'Dehydrated beetroot is sweet and tasty, providing a nutritious treat for horses when fed in moderation. The dehydration process concentrates its natural sugars and enhances its vibrant color, making it an attractive snack.',
    benefits: [
      {
        title: 'Rich in Vitamins and Minerals',
        description: 'Provides essential nutrients that support overall health.',
      },
      {
        title: 'High in Antioxidants',
        description: 'Helps protect cells from damage.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents constipation.',
      },
      {
        title: 'Low in Calories',
        description: 'Suitable for regular feeding without adding extra calories.',
      },
    ],
    uses: [
      {
        title: 'Occasional Treat',
        description: 'Best given in moderation due to its sugar content.',
      },
      {
        title: 'Dietary Supplement',
        description: 'Adds essential nutrients and variety to the diet.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses enjoy the natural sweetness and vibrant color of dehydrated beetroot, making it a delightful and healthy treat. Its taste and nutritional benefits make it highly enjoyable for horses.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '15-20%' },
      { title: 'Energy (ME)', value: '2800-3000 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '4000-6000 IU/kg' },
      { title: 'Vitamin C', value: '600-800 mg/kg' },
      { title: 'Crude Protein', value: '7.0-9.0%' },
      { title: 'Crude Fiber', value: '6.0-8.0%' },
      { title: 'Fat', value: '1.0-2.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '3.0-5.0%' },
      { title: 'Fiber', value: '6.0-8.0%' },
      { title: 'Energy', value: '2.8-3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '70-80%' },
    ],
    feedingInstruction: [
      {
        title: 'Quantity',
        description:
          "Offer dehydrated beetroot as a supplement or treat in moderation. Start with small amounts and gradually increase based on your horse's response.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated beetroot by soaking it in water for a few hours or overnight, or feed it dry depending on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: 'Provide dehydrated beetroot as a supplement or treat a few times a week, rather than daily, to maintain dietary balance.',
      },
      {
        title: 'Monitoring',
        description: "Monitor your horse's reaction to the dehydrated beetroot, including any changes in appetite, stool consistency, or behavior.",
      },
      {
        title: 'Incorporation',
        description: "Mix rehydrated beetroot into your horse's regular feed as a flavorful addition, or offer it separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description:
          "Offer small portions of dehydrated beetroot initially, such as a handful or a few slices, and adjust based on your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description: 'Supervise your horse while they consume the dehydrated beetroot to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Radish',
    type: 'horse',
    name: 'Dehydrated Radish (Mooli)',
    image: '../assets/images/products/horses/radish-mooli.jpg',
    description:
      'Dehydrated radish is spicy and crunchy, offering an interesting treat for horses in small amounts. The dehydration process enhances its natural flavor and texture, making it a unique snack.',
    benefits: [
      {
        title: 'Rich in Vitamins and Minerals',
        description: 'Provides essential nutrients that support overall health.',
      },
      {
        title: 'Digestive Aid',
        description: 'Known to aid digestion and promote gut health.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents constipation.',
      },
      {
        title: 'Low in Calories',
        description: 'Suitable for regular feeding without adding extra calories.',
      },
    ],
    uses: [
      {
        title: 'Small Treat',
        description: 'Best given in small amounts due to its spiciness.',
      },
      {
        title: 'Dietary Supplement',
        description: 'Adds essential nutrients and variety to the diet.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses find the spicy crunch of dehydrated radish intriguing, making it a unique and healthy snack in small quantities. Its distinctive flavor and texture make it an enjoyable treat.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3000 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '4000-6000 IU/kg' },
      { title: 'Vitamin C', value: '600-800 mg/kg' },
      { title: 'Crude Protein', value: '7.0-9.0%' },
      { title: 'Crude Fiber', value: '6.0-8.0%' },
      { title: 'Fat', value: '1.0-2.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '3.0-5.0%' },
      { title: 'Fiber', value: '6.0-8.0%' },
      { title: 'Energy', value: '2.8-3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '70-80%' },
    ],
    feedingInstruction: [
      {
        title: 'Quantity',
        description:
          "Introduce dehydrated radish gradually into your horse's diet to assess tolerance and preference. Start with small portions and increase gradually based on your horse's response.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated radish by soaking it in water for a few hours or overnight, or offer it dry depending on your horse's preference.",
      },
      {
        title: 'Frequency',
        description:
          'Provide dehydrated radish as an occasional treat or supplement, rather than a primary feed component, to maintain dietary variety.',
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated radish.',
      },
      {
        title: 'Incorporation',
        description: "Mix rehydrated radish into your horse's regular feed to add flavor and variety, or offer it separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially, such as a handful or a few slices, and adjust based on your horse's size and dietary needs.",
      },
      {
        title: 'Supervision',
        description: 'Always supervise your horse while consuming dehydrated radish to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Turnips',
    type: 'horse',
    name: 'Dehydrated Turnips (Shalgam)',
    image: '../assets/images/products/horses/Dehydrated-Turnips.png',

    description:
      'Dehydrated turnips are crunchy and sweet, providing a favorite treat for many horses. The dehydration process preserves their natural sweetness and enhances their nutritional profile, making them a healthy and delicious snack.',
    benefits: [
      {
        title: 'Rich in Vitamins and Minerals',
        description: 'Provides essential nutrients that support overall health.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents constipation.',
      },
      {
        title: 'Low in Calories',
        description: 'Suitable for regular feeding without adding extra calories.',
      },
      {
        title: 'Antioxidants',
        description: 'Contains compounds that help protect cells from damage.',
      },
    ],
    uses: [
      {
        title: 'Healthy Snack',
        description: 'Can be given as a crunchy, healthy snack any time of the day.',
      },
      {
        title: 'Dietary Supplement',
        description: 'Adds essential nutrients and variety to the diet.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses enjoy the sweet, crunchy texture of dehydrated turnips, making them a nutritious and favored treat. Their appealing flavor and crunchiness make them highly enjoyable for horses.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3000 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '4000-6000 IU/kg' },
      { title: 'Vitamin C', value: '600-800 mg/kg' },
      { title: 'Crude Protein', value: '7.0-9.0%' },
      { title: 'Crude Fiber', value: '6.0-8.0%' },
      { title: 'Fat', value: '1.0-2.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '3.0-5.0%' },
      { title: 'Fiber', value: '6.0-8.0%' },
      { title: 'Energy', value: '2.8-3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '70-80%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated turnips gradually into your horse's diet to monitor tolerance and preference.",
      },
      {
        title: 'Quantity',
        description: "Start with small portions and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated turnips by soaking them in water for several hours or overnight before feeding, or offer them dry based on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated turnips as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated turnips.',
      },
      {
        title: 'Incorporation',
        description:
          "Mix rehydrated turnips into your horse's regular feed to enhance flavor and texture, or provide them separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description:
          "Offer small portions initially, such as a handful or a few slices, and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated turnips to ensure they chew them thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Sweet-Potatoes',
    type: 'horse',
    name: 'Dehydrated Sweet Potatoes (Shakarkandi)',
    image: '../assets/images/products/horses/sweet-patato.jpg',

    description:
      'Dehydrated sweet potatoes are sweet and starchy, providing a nutritious treat for horses when fed in moderation. The dehydration process concentrates their natural sugars and enhances their flavor, making them a delicious snack.',
    benefits: [
      {
        title: 'Rich in Vitamins A and C',
        description: 'Supports immune function and skin health.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents constipation.',
      },
      {
        title: 'Low in Fat',
        description: 'Suitable for horses that require low-fat diets.',
      },
      {
        title: 'Antioxidants',
        description: 'Contains compounds that help protect cells from damage.',
      },
    ],
    uses: [
      {
        title: 'Occasional Treat',
        description: 'Best given in moderation due to their high sugar content.',
      },
      {
        title: 'Dietary Supplement',
        description: 'Adds essential nutrients and variety to the diet.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses find the sweet and starchy flavor of dehydrated sweet potatoes delicious, making them a favorite treat. Their taste and nutritional benefits make them highly enjoyable for horses.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '5-8%' },
      { title: 'Fiber', value: '15-20%' },
      { title: 'Energy (ME)', value: '3000-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '600-800 mg/kg' },
      { title: 'Crude Protein', value: '4.0-7.0%' },
      { title: 'Crude Fiber', value: '5.0-8.0%' },
      { title: 'Fat', value: '1.0-2.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '3.0-5.0%' },
      { title: 'Fiber', value: '5.0-8.0%' },
      { title: 'Energy', value: '3.0-3.2 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '70-80%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated sweet potatoes gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description: "Begin with small portions and gradually increase based on your horse's response and dietary requirements.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated sweet potatoes by soaking them in water for several hours or overnight before feeding, or offer them dry depending on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated sweet potatoes as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated sweet potatoes.',
      },
      {
        title: 'Incorporation',
        description:
          "Mix rehydrated sweet potatoes into your horse's regular feed to enhance flavor and texture, or provide them separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description:
          "Offer small portions initially, such as a handful or a few slices, and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated sweet potatoes to ensure they chew them thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Taro-Root',
    type: 'horse',
    name: 'Dehydrated Taro Root (Arbi)',

    image: '../assets/images/products/horses/Dehydrated-Taro-Root.jpg',
    description:
      'Dehydrated taro root is nutty and starchy, providing a nutritious treat for horses when fed in moderation. The dehydration process enhances its natural flavor and preserves its nutrients, making it a beneficial snack.',
    benefits: [
      {
        title: 'Rich in Vitamins and Minerals',
        description: 'Provides essential nutrients that support overall health.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents constipation.',
      },
      {
        title: 'Low in Fat',
        description: 'Suitable for horses that require low-fat diets.',
      },
      {
        title: 'Antioxidants',
        description: 'Contains compounds that help protect cells from damage.',
      },
    ],
    uses: [
      {
        title: 'Moderate Treat',
        description: 'Best given in moderation to avoid digestive issues.',
      },
      {
        title: 'Dietary Supplement',
        description: 'Adds essential nutrients and variety to the diet.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses enjoy the nutty and starchy flavor of dehydrated taro root, making it a tasty and healthy snack when fed in moderation. Its unique taste and texture make it enjoyable for horses.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '5-8%' },
      { title: 'Fiber', value: '15-20%' },
      { title: 'Energy (ME)', value: '3000-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '600-800 mg/kg' },
      { title: 'Crude Protein', value: '4.0-7.0%' },
      { title: 'Crude Fiber', value: '5.0-8.0%' },
      { title: 'Fat', value: '1.0-2.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '3.0-5.0%' },
      { title: 'Fiber', value: '5.0-8.0%' },
      { title: 'Energy', value: '3.0-3.2 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '70-80%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description:
          "When introducing dehydrated taro root into a horse's diet, start with small quantities and gradually increase over time. This helps the horse's digestive system adjust to the new feed.",
      },
      {
        title: 'Incorporation',
        description: "Dehydrated taro root can be mixed with the horse's regular feed or given as a standalone treat.",
      },
      {
        title: 'Moderation',
        description:
          "While taro root can be a nutritious addition to a horse's diet, it should be fed in moderation. Excessive consumption may lead to digestive upset or other health issues.",
      },
      {
        title: 'Monitoring',
        description:
          'Keep an eye on your horse for any signs of digestive discomfort after introducing dehydrated taro root. If you notice any changes in behavior or appetite, consider adjusting the amount or frequency of feeding.',
      },
      {
        title: 'Hydration',
        description:
          'Always ensure your horse has access to fresh, clean water, especially when introducing new feed items like dehydrated taro root.',
      },
      {
        title: 'Consultation',
        description:
          'If you have any concerns about feeding dehydrated taro root to your horse or if your horse has any underlying health conditions, consult with a veterinarian for personalized advice and recommendations.',
      },
    ],
  },
  {
    id: 'Dehydrated-Ginger-Root',
    type: 'horse',
    name: 'Dehydrated Ginger Root (Adrak)',
    image: '../assets/images/products/horses/dried-ginger-adrak.webp',

    description:
      'Dehydrated ginger root can be a tasty and healthy treat for horses in small amounts. Known for its medicinal properties, ginger root provides unique health benefits and a distinctive flavor.',
    benefits: [
      {
        title: 'Anti-inflammatory Properties',
        description: 'Helps reduce inflammation and improve joint health.',
      },
      {
        title: 'Digestive Aid',
        description: 'Known to aid digestion and prevent nausea.',
      },
      {
        title: 'Rich in Antioxidants',
        description: 'Helps protect cells from damage.',
      },
    ],
    uses: [
      {
        title: 'Small Treat',
        description: 'Best given in small amounts due to its strong flavor.',
      },
      {
        title: 'Health Supplement',
        description: 'Can be used for its medicinal properties to support overall health.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Some horses enjoy the spicy and distinctive flavor of dehydrated ginger root, making it a unique and beneficial treat in small quantities. Its health benefits and unique taste make it enjoyable for horses.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '5-8%' },
      { title: 'Fiber', value: '10-15%' },
      { title: 'Energy (ME)', value: '2800-3000 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '3000-5000 IU/kg' },
      { title: 'Vitamin C', value: '600-800 mg/kg' },
      { title: 'Crude Protein', value: '4.0-7.0%' },
      { title: 'Crude Fiber', value: '5.0-8.0%' },
      { title: 'Fat', value: '1.0-2.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '3.0-5.0%' },
      { title: 'Fiber', value: '5.0-8.0%' },
      { title: 'Energy', value: '2.8-3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '70-80%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated ginger gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description: "Start with small amounts and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated ginger by soaking it in water for a few hours or overnight before feeding, or offer it dry based on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated ginger as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated ginger.',
      },
      {
        title: 'Incorporation',
        description:
          "Mix rehydrated ginger into your horse's regular feed to enhance flavor and aroma, or provide it separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially, such as a teaspoonful, and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description: 'Always supervise your horse while consuming dehydrated ginger to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Elephant-Foot-Yam',
    type: 'horse',
    name: 'Dehydrated Elephant Foot Yam (Jimikand/Suran)',
    image: '../assets/images/products/horses/elphant-foot-yarm.jpg',
    description:
      'Dehydrated elephant foot yam can be fed in small amounts, offering a nutritious and unique treat for horses. The dehydration process enhances its natural flavor and preserves its nutritional value.',
    benefits: [
      {
        title: 'Rich in Vitamins and Minerals',
        description: 'Provides essential nutrients that support overall health.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents constipation.',
      },
      {
        title: 'Low in Fat',
        description: 'Suitable for horses that require low-fat diets.',
      },
      {
        title: 'Antioxidants',
        description: 'Contains compounds that help protect cells from damage.',
      },
    ],
    uses: [
      {
        title: 'Moderate Treat',
        description: 'Best given in small amounts to avoid digestive issues.',
      },
      {
        title: 'Dietary Supplement',
        description: 'Adds essential nutrients and variety to the diet.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses enjoy the unique flavor and texture of dehydrated elephant foot yam, making it a tasty and healthy snack when fed in moderation. Its distinctive taste and nutritional benefits make it enjoyable for horses.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-10%' },
      { title: 'Fiber', value: '3-5%' },
      { title: 'Energy (ME)', value: '3400-3800 kcal/kg' },
      { title: 'Calcium', value: '0.05-0.1%' },
      { title: 'Phosphorus', value: '0.1-0.3%' },
      { title: 'Vitamin A', value: '500-800 IU/kg' },
      { title: 'Vitamin C', value: '10-20 mg/kg' },
      { title: 'Crude Protein', value: '7-9%' },
      { title: 'Crude Fiber', value: '2-4%' },
      { title: 'Fat', value: '0.5-1.0%' },
      { title: 'Moisture', value: '5-8%' },
      { title: 'Ash', value: '1-2%' },
      { title: 'Fiber', value: '3-5%' },
      { title: 'Energy', value: '3.4-3.8 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '70-75%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated elephant foot yam gradually into your horse's diet to assess tolerance and preference.",
      },
      {
        title: 'Quantity',
        description: "Begin with small amounts and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated elephant foot yam by soaking it in water for a few hours or overnight before feeding, or offer it dry based on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated elephant foot yam as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated elephant foot yam.',
      },
      {
        title: 'Incorporation',
        description:
          "Mix rehydrated elephant foot yam into your horse's regular feed to enhance flavor and aroma, or provide it separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description: "Offer small portions initially, such as a teaspoonful, and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description:
          'Always supervise your horse while consuming dehydrated elephant foot yam to ensure they chew it thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, especially when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'Dehydrated-Yams',
    type: 'horse',
    name: 'Dehydrated Yams (Ratalu)',
    image: '../assets/images/products/horses/elephant-foot.jpg',
    description:
      'Dehydrated yams are similar to sweet potatoes but should be given in moderation. The dehydration process enhances their natural sweetness and nutritional value, making them a delicious and nutritious treat.',
    benefits: [
      {
        title: 'Rich in Vitamins A and C',
        description: 'Supports immune function and skin health.',
      },
      {
        title: 'High in Fiber',
        description: 'Promotes healthy digestion and prevents constipation.',
      },
      {
        title: 'Low in Fat',
        description: 'Suitable for horses that require low-fat diets.',
      },
      {
        title: 'Antioxidants',
        description: 'Contains compounds that help protect cells from damage.',
      },
    ],
    uses: [
      {
        title: 'Occasional Treat',
        description: 'Best given in moderation due to their high sugar content.',
      },
      {
        title: 'Dietary Supplement',
        description: 'Adds essential nutrients and variety to the diet.',
      },
    ],
    enjoyment: {
      title: 'How Horses Enjoy It',
      description:
        'Horses find the sweet, starchy flavor of dehydrated yams delicious, making them a favored treat. Their taste and nutritional benefits make them highly enjoyable for horses.',
    },
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with fiber, Vitamin K, and Vitamin C.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '5-8%' },
      { title: 'Fiber', value: '15-20%' },
      { title: 'Energy (ME)', value: '3000-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '600-800 mg/kg' },
      { title: 'Crude Protein', value: '4.0-7.0%' },
      { title: 'Crude Fiber', value: '5.0-8.0%' },
      { title: 'Fat', value: '1.0-2.0%' },
      { title: 'Moisture', value: '5.0-8.0%' },
      { title: 'Ash', value: '3.0-5.0%' },
      { title: 'Fiber', value: '5.0-8.0%' },
      { title: 'Energy', value: '3.0-3.2 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '70-80%' },
    ],
    feedingInstruction: [
      {
        title: 'Introduction',
        description: "Introduce dehydrated yams gradually into your horse's diet to gauge tolerance and preference.",
      },
      {
        title: 'Quantity',
        description: "Start with small portions and gradually increase based on your horse's response and dietary needs.",
      },
      {
        title: 'Preparation',
        description:
          "Rehydrate the dehydrated yams by soaking them in water for several hours or overnight before feeding, or offer them dry based on your horse's preference.",
      },
      {
        title: 'Frequency',
        description: "Offer dehydrated yams as an occasional treat or supplement to add variety to your horse's diet.",
      },
      {
        title: 'Monitoring',
        description: 'Monitor your horse for any adverse reactions or digestive issues after consuming dehydrated yams.',
      },
      {
        title: 'Incorporation',
        description:
          "Mix rehydrated yams into your horse's regular feed to enhance flavor and texture, or provide them separately as standalone treats.",
      },
      {
        title: 'Portion Size',
        description:
          "Offer small portions initially, such as a handful or a few slices, and adjust according to your horse's size and dietary requirements.",
      },
      {
        title: 'Supervision',
        description: 'Always supervise your horse while consuming dehydrated yams to ensure they chew them thoroughly and prevent choking hazards.',
      },
      {
        title: 'Hydration',
        description: 'Ensure your horse has access to fresh water at all times, particularly when feeding dehydrated treats or supplements.',
      },
    ],
  },
  {
    id: 'dehydrated-carrots',
    name: 'Dehydrated Carrots',
    image: '../assets/images/products/horses/dehydrated-carrot-powder.webp',
    description:
      'Dehydrated carrots are produced by removing moisture from fresh carrots, resulting in a lightweight, shelf-stable product available in flakes or powder form.',
    benefits: [
      {
        title: 'Rich in Beta-Carotene',
        description: 'Carrots are high in beta-carotene, a precursor to Vitamin A, essential for eye health and immune function in dairy cattle.',
      },
      {
        title: 'Excellent Source of Fiber',
        description: 'Provides dietary fiber, promoting digestive health and regulating bowel movements in cattle.',
      },
      {
        title: 'Nutrient Dense',
        description:
          'Contains essential vitamins and minerals such as Vitamin K, potassium, and antioxidants, supporting overall health and vitality in dairy cattle.',
      },
    ],
    uses: [
      { description: 'Incorporate into daily feed ration to provide essential nutrients and promote overall well-being.' },
      { description: 'Serve as a natural and nutritious supplement or treat during milking or feeding times.' },
    ],
    specifications: [{ title: 'Nutritional Value', description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.' }],
    ingredientValues: [
      { title: 'Protein', value: '10%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '7.0%' },
      { title: 'Crude Fiber', value: '2.8%' },
      { title: 'Fat', value: '0.2%' },
      { title: 'Moisture', value: '6.0%' },
      { title: 'Ash', value: '3.0%' },
      { title: 'Fiber', value: '2.8%' },
      { title: 'Energy', value: '3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '75.0%' },
    ],
    feedingInstruction: [
      { description: 'Feed 1-2 lbs per head per day.' },
      { description: 'Offer dehydrated carrots as part of the daily feed ration.' },
      { description: 'Ensure cattle have access to clean, fresh water at all times.' },
      { description: 'Monitor for any signs of digestive issues or changes in appetite.' },
    ],
    type: 'dairy-cattle',
  },
  {
    id: 'dehydrated-sweet-potatoes',
    name: 'Dehydrated Sweet Potatoes',
    image: '../assets/images/products/horses/Dehydrated Sweet Potatoes.png',

    description:
      'Dehydrated sweet potatoes are made by drying fresh sweet potatoes, resulting in a concentrated source of nutrients available in flakes or powder form.',
    benefits: [
      {
        title: 'High Energy Content',
        description: 'Sweet potatoes are rich in carbohydrates, providing sustained energy for dairy cattle throughout the day.',
      },
      {
        title: 'Rich in Vitamins and Minerals',
        description: 'Contains Vitamin A, Vitamin C, and potassium, supporting immune function and overall health.',
      },
      { title: 'Dietary Fiber', description: 'Offers dietary fiber, aiding in digestion and reducing the risk of digestive issues in cattle.' },
    ],
    uses: [
      { description: 'Add to feed ration to enhance energy levels and support muscle function.' },
      { description: 'Serve as a natural source of vitamins and minerals to supplement the diet of lactating cows for optimal milk production.' },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Excellent source of energy, vitamins, and minerals, including Vitamin A, Vitamin C, and potassium.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '7.0%' },
      { title: 'Crude Fiber', value: '2.8%' },
      { title: 'Fat', value: '0.2%' },
      { title: 'Moisture', value: '6.0%' },
      { title: 'Ash', value: '3.0%' },
      { title: 'Fiber', value: '2.8%' },
      { title: 'Energy', value: '3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '75.0%' },
    ],
    feedingInstruction: [
      { description: 'Introduce gradually into the diet, starting with small amounts.' },
      { description: 'Offer 1-2 pounds per head per day.' },
      { description: 'Monitor intake to prevent overfeeding.' },
      { description: 'Adjust quantity based on body condition and energy needs of the cattle.' },
    ],
    type: 'dairy-cattle',
  },
  {
    id: 'dehydrated-beets',
    name: 'Dehydrated Beets',
    image: '../assets/images/products/horses/sweet-patato.jpg',
    description: 'Dehydrated beets are produced by drying fresh beets, resulting in a nutrient-dense product available in flakes or powder form.',
    benefits: [
      {
        title: 'High in Antioxidants',
        description: 'Contains betalains, potent antioxidants that support immune function and reduce oxidative stress in dairy cattle.',
      },
      { title: 'Dietary Fiber', description: 'Rich in fiber, promoting digestive health and reducing the risk of digestive issues.' },
      { title: 'Essential Minerals', description: 'Contains potassium and manganese, supporting bone health and muscle function in cattle.' },
    ],
    uses: [
      { description: 'Incorporate into feed ration to provide antioxidants and support overall health and well-being.' },
      { description: 'Serve as a natural source of nutrients to enhance the palatability of feed and encourage intake.' },
    ],
    specifications: [{ title: 'Nutritional Value', description: 'High in fiber, energy, and digestible carbohydrates.' }],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '7.0%' },
      { title: 'Crude Fiber', value: '2.8%' },
      { title: 'Fat', value: '0.2%' },
      { title: 'Moisture', value: '6.0%' },
      { title: 'Ash', value: '3.0%' },
      { title: 'Fiber', value: '2.8%' },
      { title: 'Energy', value: '3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '75.0%' },
    ],
    feedingInstruction: [
      { description: 'Feed 1-2 pounds per head per day.' },
      { description: 'Ensure proper chewing to aid digestion.' },
      { description: 'Monitor for any adverse reactions, such as decreased appetite or digestive upset.' },
    ],
    type: 'dairy-cattle',
  },
  {
    id: 'dehydrated-peas',
    name: 'Dehydrated Peas',
    image: '../assets/images/products/horses/Dehydrated-Peas.jpg',
    description: 'Dehydrated peas are made by drying fresh peas, resulting in a nutritious product available in flakes or powder form.',
    benefits: [
      { title: 'High Protein Content', description: 'Peas are rich in protein, supporting muscle growth and repair in dairy cattle.' },
      { title: 'Dietary Fiber', description: 'Contains dietary fiber, promoting digestive health and reducing the risk of digestive issues.' },
      { title: 'Essential Nutrients', description: 'Contains Vitamin K, Vitamin C, and manganese, supporting immune function and overall health.' },
    ],
    uses: [
      { description: 'Add to feed ration to increase protein intake and support muscle development in growing or lactating dairy cattle.' },
      { description: 'Serve as a natural source of fiber to aid in rumen function and promote optimal digestion.' },
    ],
    specifications: [
      { title: 'Nutritional Value', description: 'High in protein, fiber, and essential nutrients like Vitamin K, Vitamin C, and manganese.' },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '7.0%' },
      { title: 'Crude Fiber', value: '2.8%' },
      { title: 'Fat', value: '0.2%' },
      { title: 'Moisture', value: '6.0%' },
      { title: 'Ash', value: '3.0%' },
      { title: 'Fiber', value: '2.8%' },
      { title: 'Energy', value: '3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '75.0%' },
    ],
    feedingInstruction: [
      { description: 'Mix dehydrated peas with other feed for balanced nutrition.' },
      { description: 'Offer 1-2 pounds per head per day.' },
      { description: 'Monitor for changes in appetite or digestive issues.' },
    ],
    type: 'dairy-cattle',
  },
  {
    id: 'dehydrated-pumpkin',
    name: 'Dehydrated Pumpkin',
    image: '../assets/images/products/horses/dehydrated-pumpkin.jpg',
    description: 'Dehydrated pumpkin is made by drying fresh pumpkin, resulting in a nutrient-rich product available in flakes or powder form.',
    benefits: [
      {
        title: 'Rich in Vitamins',
        description: 'Contains Vitamin A, Vitamin C, and other essential vitamins, supporting immune function and overall health.',
      },
      { title: 'Dietary Fiber', description: 'Provides dietary fiber, promoting digestive health and regulating bowel movements in cattle.' },
      { title: 'Antioxidants', description: 'Offers antioxidants that reduce oxidative stress and support cellular health.' },
    ],
    uses: [
      { description: 'Add to feed ration to provide essential vitamins and minerals for overall health and well-being.' },
      { description: 'Serve as a natural source of nutrients to enhance the palatability of feed and encourage intake.' },
    ],
    specifications: [{ title: 'Nutritional Value', description: 'High in fiber, Vitamin A, and antioxidants.' }],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '7.0%' },
      { title: 'Crude Fiber', value: '2.8%' },
      { title: 'Fat', value: '0.2%' },
      { title: 'Moisture', value: '6.0%' },
      { title: 'Ash', value: '3.0%' },
      { title: 'Fiber', value: '2.8%' },
      { title: 'Energy', value: '3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '75.0%' },
    ],
    feedingInstruction: [
      { description: "Incorporate dehydrated pumpkin into the cattle's feed for enhanced nutrition." },
      { description: 'Ensure thorough mixing with other feed ingredients to achieve a balanced diet.' },
      { description: 'Offer dehydrated pumpkin to cattle at a rate of 1-2 pounds per head per day.' },
      {
        description:
          'Adjust the quantity based on the size, weight, and nutritional requirements of the cattle, as well as the availability of other feed sources.',
      },
      { description: 'Monitor the cattle for any changes in appetite or digestive issues after introducing dehydrated pumpkin into their feed.' },
      {
        description:
          'Watch for signs of decreased appetite, gastrointestinal discomfort, or abnormal behavior, and adjust the feeding amount accordingly.',
      },
      { description: "Introduce dehydrated pumpkin gradually into the cattle's diet, particularly if they are not accustomed to it." },
      { description: 'Start with a smaller amount and gradually increase the quantity over time to allow the cattle to adjust to the new feed.' },
    ],
    type: 'dairy-cattle',
  },
  {
    id: 'dehydrated-spinach',
    name: 'Dehydrated Spinach',
    image: '../assets/images/products/horses/green-peas.webp',
    description:
      'Dehydrated spinach is made by drying fresh spinach, resulting in a concentrated source of nutrients available in flakes or powder form.',
    benefits: [
      {
        title: 'Rich in Vitamins',
        description: 'Contains Vitamin A, Vitamin C, Vitamin K, and other essential vitamins, supporting immune function and overall health.',
      },
      { title: 'Mineral-Rich', description: 'Provides minerals such as iron and calcium, supporting bone health and muscle function in cattle.' },
      { title: 'Antioxidants', description: 'Offers antioxidants that reduce oxidative stress and support cellular health.' },
    ],
    uses: [
      { description: 'Incorporate into daily feed ration to provide essential vitamins and minerals for overall health and well-being.' },
      { description: 'Serve as a natural and nutritious supplement to promote optimal health in lactating cows.' },
    ],
    specifications: [
      {
        title: 'Nutritional Value',
        description: 'Packed with vitamins (Vitamin A, Vitamin C, Vitamin K), minerals (iron, calcium), and antioxidants.',
      },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '7.0%' },
      { title: 'Crude Fiber', value: '2.8%' },
      { title: 'Fat', value: '0.2%' },
      { title: 'Moisture', value: '6.0%' },
      { title: 'Ash', value: '3.0%' },
      { title: 'Fiber', value: '2.8%' },
      { title: 'Energy', value: '3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '75.0%' },
    ],
    feedingInstruction: [
      { description: 'Offer 1-2 pounds per head per day.' },
      { description: 'Ensure proper mixing with other feed to prevent selective eating.' },
      { description: 'Monitor for any signs of nutrient deficiencies or digestive upset.' },
    ],
    type: 'dairy-cattle',
  },
  {
    id: 'dehydrated-cabbage',
    name: 'Dehydrated Cabbage',
    image: '../assets/images/products/horses/dried-cabbage-flakes.webp',
    description: 'Dehydrated cabbage is made by drying fresh cabbage, resulting in a nutrient-dense product available in flakes or powder form.',
    benefits: [
      {
        title: 'Vitamins and Minerals',
        description: 'Contains Vitamin K, Vitamin C, and other essential vitamins and minerals, supporting immune function and overall health.',
      },
      { title: 'Dietary Fiber', description: 'Provides dietary fiber, promoting digestive health and reducing the risk of digestive issues.' },
      { title: 'Antioxidants', description: 'Offers antioxidants that reduce oxidative stress and support cellular health.' },
    ],
    uses: [
      { description: 'Incorporate into daily feed ration to provide essential vitamins and minerals for overall health and well-being.' },
      { description: 'Serve as a natural and nutritious supplement to promote optimal health in lactating cows.' },
    ],
    specifications: [
      { title: 'Nutritional Value', description: 'Packed with fiber, vitamins (including Vitamin K and Vitamin C), and phytonutrients.' },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '7.0%' },
      { title: 'Crude Fiber', value: '2.8%' },
      { title: 'Fat', value: '0.2%' },
      { title: 'Moisture', value: '6.0%' },
      { title: 'Ash', value: '3.0%' },
      { title: 'Fiber', value: '2.8%' },
      { title: 'Energy', value: '3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '75.0%' },
    ],
    feedingInstruction: [
      { description: 'Feed 1-2 pounds per head per day.' },
      { description: 'Monitor intake and adjust quantity based on individual cattle needs.' },
      { description: 'Introduce slowly to prevent digestive upset.' },
    ],
    type: 'dairy-cattle',
  },
  {
    id: 'dehydrated-radish',
    name: 'Dehydrated Radish',
    image: '../assets/images/products/horses/radish-mooli.jpg',
    description: 'Dehydrated radish is made by drying fresh radishes, resulting in a nutrient-dense product available in flakes or powder form.',
    benefits: [
      {
        title: 'Fiber Source',
        description: 'Rich in fiber, dehydrated radish promotes digestive health and regulates bowel movements in dairy cattle.',
      },
      {
        title: 'Vitamins and Minerals',
        description: 'Contains Vitamin C, Vitamin K, and other essential nutrients for immune function and overall health.',
      },
      { title: 'Antioxidants', description: 'Provides antioxidants that reduce oxidative stress and support cellular health.' },
    ],
    uses: [
      { description: 'Add to feed ration to provide essential vitamins, minerals, and fiber for overall health and well-being.' },
      { description: 'Serve as a flavorful and nutritious supplement or treat to encourage intake and improve palatability.' },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '7.0%' },
      { title: 'Crude Fiber', value: '2.8%' },
      { title: 'Fat', value: '0.2%' },
      { title: 'Moisture', value: '6.0%' },
      { title: 'Ash', value: '3.0%' },
      { title: 'Fiber', value: '2.8%' },
      { title: 'Energy', value: '3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '75.0%' },
    ],

    feedingInstruction: [
      { description: 'Offer 1-2 pounds per head per day.' },
      { description: 'Monitor for any signs of digestive discomfort or changes in behavior.' },
      { description: 'Ensure proper mixing with other feed to prevent selective eating.' },
    ],
    type: 'dairy-cattle',
  },
  {
    id: 'dehydrated-cauliflower',
    name: 'Dehydrated Cauliflower',
    image: '../assets/images/products/horses/cauliflower-dehydrated.webp',
    description:
      'Dehydrated cauliflower is created by drying fresh cauliflower, resulting in a nutrient-rich product available in flakes or powder form.',
    benefits: [
      {
        title: 'Vitamins and Minerals',
        description:
          'Contains Vitamin K, Vitamin C, and other essential vitamins and minerals for immune function and overall health in dairy cattle.',
      },
      { title: 'Fiber Source', description: 'Provides dietary fiber, promoting digestive health and regulating bowel movements.' },
      { title: 'Low in Calories', description: 'Low-calorie option suitable for weight management in cattle.' },
    ],
    uses: [
      { description: 'Incorporate into feed ration to provide essential vitamins, minerals, and fiber for overall health and well-being.' },
      { description: 'Offer as a natural and nutritious supplement or treat to encourage intake and improve palatability.' },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '7.0%' },
      { title: 'Crude Fiber', value: '2.8%' },
      { title: 'Fat', value: '0.2%' },
      { title: 'Moisture', value: '6.0%' },
      { title: 'Ash', value: '3.0%' },
      { title: 'Fiber', value: '2.8%' },
      { title: 'Energy', value: '3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '75.0%' },
    ],
    feedingInstruction: [
      { description: 'Offer 1-2 pounds of dehydrated cauliflower per head per day.' },
      { description: 'Monitor for any adverse reactions, such as bloating or gastrointestinal issues.' },
      { description: 'Introduce gradually into the diet to prevent digestive upset.' },
    ],
    type: 'dairy-cattle',
  },
  {
    id: 'dehydrated-banana',
    name: 'Dehydrated Banana',
    image: '../assets/images/products/horses/banana-powder.jpg',
    description: 'Dehydrated banana is made by drying fresh bananas, resulting in a nutrient-dense product available in flakes or powder form.',
    benefits: [
      { title: 'High Energy', description: 'Bananas are rich in carbohydrates, providing sustained energy for dairy cattle throughout the day.' },
      { title: 'Potassium Source', description: 'Contains potassium, an essential electrolyte for muscle function and overall health.' },
      { title: 'Digestive Aid', description: 'Offers dietary fiber, aiding in digestion and promoting gut health in cattle.' },
    ],
    uses: [
      { description: 'Add to feed ration to enhance energy levels and support muscle function.' },
      { description: 'Serve as a natural and nutritious supplement or treat during milking or feeding times.' },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '7.0%' },
      { title: 'Crude Fiber', value: '2.8%' },
      { title: 'Fat', value: '0.2%' },
      { title: 'Moisture', value: '6.0%' },
      { title: 'Ash', value: '3.0%' },
      { title: 'Fiber', value: '2.8%' },
      { title: 'Energy', value: '3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '75.0%' },
    ],
    feedingInstruction: [
      { description: 'Offer 1-2 pounds of dehydrated banana per head per day.' },
      { description: 'Monitor for any signs of digestive discomfort or changes in behavior.' },
      { description: 'Ensure proper mixing with other feed to prevent selective eating.' },
    ],
    type: 'dairy-cattle',
  },
  {
    id: 'dry-dates-powder',
    name: 'Dry Dates Powder',
    image: '../assets/images/products/horses/dry-dates-powder.webp',

    description: 'Dry dates powder is made by grinding dried dates into a fine powder, providing a concentrated source of nutrients.',
    benefits: [
      { title: 'Energy Boost', description: 'Dates are rich in natural sugars, providing a quick and sustained energy boost for dairy cattle.' },
      {
        title: 'Vitamins and Minerals',
        description: 'Contains Vitamin A, Vitamin K, potassium, and other essential nutrients for immune function and overall health.',
      },
      { title: 'Digestive Health', description: 'Provides dietary fiber, promoting digestive health and regulating bowel movements.' },
    ],
    uses: [
      { description: 'Incorporate into feed ration to provide energy and essential nutrients for overall health and well-being.' },
      { description: 'Serve as a natural and nutritious supplement or treat to encourage intake and improve palatability.' },
    ],
    ingredientValues: [
      { title: 'Protein', value: '8-12%' },
      { title: 'Fiber', value: '20-25%' },
      { title: 'Energy (ME)', value: '2800-3200 kcal/kg' },
      { title: 'Calcium', value: '0.5-1.0%' },
      { title: 'Phosphorus', value: '0.3-0.6%' },
      { title: 'Vitamin A', value: '5000-8000 IU/kg' },
      { title: 'Vitamin C', value: '800-1200 mg/kg' },
      { title: 'Crude Protein', value: '7.0%' },
      { title: 'Crude Fiber', value: '2.8%' },
      { title: 'Fat', value: '0.2%' },
      { title: 'Moisture', value: '6.0%' },
      { title: 'Ash', value: '3.0%' },
      { title: 'Fiber', value: '2.8%' },
      { title: 'Energy', value: '3.0 Mcal/kg' },
      { title: 'Digestible Carbohydrates', value: '75.0%' },
    ],
    feedingInstruction: [
      { description: 'Offer 1-2 pounds of dry dates powder per head per day.' },
      { description: 'Monitor intake and adjust quantity based on individual cattle preferences.' },
      { description: 'Ensure proper mixing with other feed to prevent selective eating.' },
    ],
    type: 'dairy-cattle',
  },

  {
    id: 'pork-Carrots',
    type: 'pork',
    ingredientValues: [],
    name: 'Carrots',
    image: '../assets/images/products/horses/carrot.jpg',
    description: 'Dehydrated carrots are rich in beta-carotene, vitamins, and minerals, providing a nutrient-dense addition to pig diets.',
    benefits: [
      {
        name: 'Enhances immune function',
        description: 'Promotes healthy skin and eyesight, and provides essential vitamins.',
      },
    ],
    uses: [
      {
        name: 'Feed Mix',
        description: 'Can be ground into a fine powder and mixed into the feed at a rate of up to 10% of the total diet.',
      },
    ],
    specifications: [
      {
        name: 'Inclusion Rate',
        description: 'Up to 10% of the total diet.',
      },
      {
        name: 'Feeding Instructions',
        description: 'Carrots should be dried and ground or pelleted. Mix with other feed components to ensure uniform distribution.',
      },
    ],
    feedingInstruction: [
      {
        name: 'Age and Weight Considerations',
        description: 'Guidelines for incorporating dry vegetables into the diet based on the age and weight of pigs.',
      },
      {
        name: 'Piglets (Weaning to 25 kg)',
        description: 'Higher protein and energy requirements. Include vegetables that support growth and immune function such as peas and carrots.',
      },
      {
        name: 'Growing Pigs (25 kg to 70 kg)',
        description:
          'Balanced diet with adequate protein and fiber. Incorporate a mix of high-energy and high-fiber vegetables like beet pulp and sweet potatoes.',
      },
      {
        name: 'Finishing Pigs (70 kg to Market Weight)',
        description:
          'Focus on energy-dense feed to promote weight gain. Include vegetables that provide sustained energy and support digestion such as potatoes and alfalfa.',
      },
    ],
  },
  {
    id: 'pork-Sweet-Potatoes',
    type: 'pork',
    ingredientValues: [],
    name: 'Sweet Potatoes',
    image: '../assets/images/products/horses/Sweet Potatoes.jpg',
    description: 'Dehydrated sweet potatoes are high in fiber, vitamins A and C, and antioxidants, making them a valuable feed component.',
    benefits: [
      {
        name: 'Provides energy',
        description: 'Supports digestion, and boosts immune health.',
      },
    ],
    uses: [
      {
        name: 'Feed Mix',
        description: 'Can be included in the feed mix up to 10%, either as dried chunks or ground powder.',
      },
    ],
    specifications: [
      {
        name: 'Inclusion Rate',
        description: 'Up to 10% of the total diet.',
      },
      {
        name: 'Feeding Instructions',
        description: 'Sweet potatoes should be dried and ground or pelleted. Ensure they are evenly mixed with the feed.',
      },
    ],
    feedingInstruction: [
      {
        name: 'Age and Weight Considerations',
        description: 'Guidelines for incorporating dry vegetables into the diet based on the age and weight of pigs.',
      },
      {
        name: 'Piglets (Weaning to 25 kg)',
        description: 'Higher protein and energy requirements. Include vegetables that support growth and immune function such as peas and carrots.',
      },
      {
        name: 'Growing Pigs (25 kg to 70 kg)',
        description:
          'Balanced diet with adequate protein and fiber. Incorporate a mix of high-energy and high-fiber vegetables like beet pulp and sweet potatoes.',
      },
      {
        name: 'Finishing Pigs (70 kg to Market Weight)',
        description:
          'Focus on energy-dense feed to promote weight gain. Include vegetables that provide sustained energy and support digestion such as potatoes and alfalfa.',
      },
    ],
  },
  {
    id: 'pork-Beet-Pulp',
    type: 'pork',
    ingredientValues: [],
    name: 'Beet Pulp',
    image: '../assets/images/products/horses/beet pulp.webp',
    description: 'Beet pulp is the fibrous material left after sugar extraction from sugar beets, rich in fiber and energy.',
    benefits: [
      {
        name: 'Aids in digestion',
        description: 'Regulates gut motility, and provides a good source of energy.',
      },
    ],
    uses: [
      {
        name: 'Feed Mix',
        description: 'Can be incorporated up to 20% of the diet, available in shredded or pelleted form.',
      },
    ],
    specifications: [
      {
        name: 'Inclusion Rate',
        description: 'Up to 20% of the total diet.',
      },
      {
        name: 'Feeding Instructions',
        description: 'Beet pulp should be dried and can be used in pellet form. Mix thoroughly with other feed ingredients.',
      },
    ],
    feedingInstruction: [
      {
        name: 'Age and Weight Considerations',
        description: 'Guidelines for incorporating dry vegetables into the diet based on the age and weight of pigs.',
      },
      {
        name: 'Piglets (Weaning to 25 kg)',
        description: 'Higher protein and energy requirements. Include vegetables that support growth and immune function such as peas and carrots.',
      },
      {
        name: 'Growing Pigs (25 kg to 70 kg)',
        description:
          'Balanced diet with adequate protein and fiber. Incorporate a mix of high-energy and high-fiber vegetables like beet pulp and sweet potatoes.',
      },
      {
        name: 'Finishing Pigs (70 kg to Market Weight)',
        description:
          'Focus on energy-dense feed to promote weight gain. Include vegetables that provide sustained energy and support digestion such as potatoes and alfalfa.',
      },
    ],
  },
  {
    id: 'pork-Peas',
    type: 'pork',
    ingredientValues: [],
    name: 'Peas',
    image: '../assets/images/products/horses/Peas.jpg',
    description: 'Dehydrated peas are high in protein, fiber, and essential amino acids, supporting muscle development and overall health.',
    benefits: [
      {
        name: 'Improves growth',
        description: 'Supports digestive health, and provides essential nutrients.',
      },
    ],
    uses: [
      {
        name: 'Feed Mix',
        description: 'Ground into meal form and included up to 20% of the diet.',
      },
    ],
    specifications: [
      {
        name: 'Inclusion Rate',
        description: 'Up to 20% of the total diet.',
      },
      {
        name: 'Feeding Instructions',
        description: 'Dried peas should be ground and mixed with other feed components to ensure balanced nutrition.',
      },
    ],
    feedingInstruction: [
      {
        name: 'Age and Weight Considerations',
        description: 'Guidelines for incorporating dry vegetables into the diet based on the age and weight of pigs.',
      },
      {
        name: 'Piglets (Weaning to 25 kg)',
        description: 'Higher protein and energy requirements. Include vegetables that support growth and immune function such as peas and carrots.',
      },
      {
        name: 'Growing Pigs (25 kg to 70 kg)',
        description:
          'Balanced diet with adequate protein and fiber. Incorporate a mix of high-energy and high-fiber vegetables like beet pulp and sweet potatoes.',
      },
      {
        name: 'Finishing Pigs (70 kg to Market Weight)',
        description:
          'Focus on energy-dense feed to promote weight gain. Include vegetables that provide sustained energy and support digestion such as potatoes and alfalfa.',
      },
    ],
  },
  {
    id: 'pork-Potatoes',
    type: 'pork',
    ingredientValues: [],
    name: 'Potatoes',
    image: '../assets/images/products/horses/Potatoes.jpg',
    description: 'Dehydrated potatoes offer a rich source of carbohydrates, vitamins, and minerals.',
    benefits: [
      {
        name: 'Provides energy',
        description: 'Supports overall health, and enhances diet palatability.',
      },
    ],
    uses: [
      {
        name: 'Feed Mix',
        description: 'Can be dried and ground, then mixed into the feed at up to 10%.',
      },
    ],
    specifications: [
      {
        name: 'Inclusion Rate',
        description: 'Up to 10% of the total diet.',
      },
      {
        name: 'Feeding Instructions',
        description: 'Dry the potatoes and grind them before mixing with other feed ingredients.',
      },
    ],
    feedingInstruction: [
      {
        name: 'Age and Weight Considerations',
        description: 'Guidelines for incorporating dry vegetables into the diet based on the age and weight of pigs.',
      },
      {
        name: 'Piglets (Weaning to 25 kg)',
        description: 'Higher protein and energy requirements. Include vegetables that support growth and immune function such as peas and carrots.',
      },
      {
        name: 'Growing Pigs (25 kg to 70 kg)',
        description:
          'Balanced diet with adequate protein and fiber. Incorporate a mix of high-energy and high-fiber vegetables like beet pulp and sweet potatoes.',
      },
      {
        name: 'Finishing Pigs (70 kg to Market Weight)',
        description:
          'Focus on energy-dense feed to promote weight gain. Include vegetables that provide sustained energy and support digestion such as potatoes and alfalfa.',
      },
    ],
  },
  {
    id: 'pork-Alfalfa',
    type: 'pork',
    ingredientValues: [],
    name: 'Alfalfa',
    image: '../assets/images/products/horses/Alfalfa.jpg',
    description: 'Alfalfa is a nutrient-rich forage high in protein, vitamins, and minerals, supporting overall health and growth.',
    benefits: [
      {
        name: 'Improves digestion',
        description: 'Provides essential nutrients, and supports immune function.',
      },
    ],
    uses: [
      {
        name: 'Feed Mix',
        description: 'Can be dried and ground or pelleted, and included up to 15% of the diet.',
      },
    ],
    specifications: [
      {
        name: 'Inclusion Rate',
        description: 'Up to 15% of the total diet.',
      },
      {
        name: 'Feeding Instructions',
        description: 'Dry and grind or pellet the alfalfa before mixing it into the feed.',
      },
    ],
    feedingInstruction: [
      {
        name: 'Age and Weight Considerations',
        description: 'Guidelines for incorporating dry vegetables into the diet based on the age and weight of pigs.',
      },
      {
        name: 'Piglets (Weaning to 25 kg)',
        description: 'Higher protein and energy requirements. Include vegetables that support growth and immune function such as peas and carrots.',
      },
      {
        name: 'Growing Pigs (25 kg to 70 kg)',
        description:
          'Balanced diet with adequate protein and fiber. Incorporate a mix of high-energy and high-fiber vegetables like beet pulp and sweet potatoes.',
      },
      {
        name: 'Finishing Pigs (70 kg to Market Weight)',
        description:
          'Focus on energy-dense feed to promote weight gain. Include vegetables that provide sustained energy and support digestion such as potatoes and alfalfa.',
      },
    ],
  },
  {
    id: 'Starter-Feed-Kids-Lambs',
    type: 'goat/sheep',
    name: 'Starter Feed for Kids/Lambs',
    image: '../assets/images/products/horses/Starter Feed for Kid.jpg',
    description:
      'Designed to meet the nutritional needs of young goats and sheep during the early stages of growth. Contains a balanced blend of protein, vitamins, and minerals to support healthy development and immune function.',
    ingredientValues: [
      { title: 'Crude Protein', value: '18-20%' },
      { title: 'Crude Fat', value: '3-5%' },
      { title: 'Crude Fiber', value: '8-10%' },
      { title: 'Calcium', value: '0.8-1.2%' },
      { title: 'Phosphorus', value: '0.6-0.8%' },
    ],
  },
  {
    id: 'Grower-Feed-Growing-Goats-Sheep',
    type: 'goat/sheep',
    name: 'Grower Feed for Growing Goats/Sheep',
    image: '../assets/images/products/horses/Grower-Feed-Growing-Goats-Sheep.jpg',
    description:
      'Formulated to support steady growth and muscle development in growing goats and sheep. Provides a balanced combination of protein, energy, and essential nutrients for optimal performance and efficiency.',
    ingredientValues: [
      { title: 'Crude Protein', value: '16-18%' },
      { title: 'Crude Fat', value: '3-4%' },
      { title: 'Crude Fiber', value: '10-12%' },
      { title: 'Calcium', value: '0.6-0.8%' },
      { title: 'Phosphorus', value: '0.5-0.7%' },
    ],
  },
  {
    id: 'Breeder-Feed-Breeding-Goats-Ewes',
    type: 'goat/sheep',
    name: 'Breeder Feed for Breeding Goats/Ewes',
    image: '../assets/images/products/horses/Breeder-Feed-Breeding-Goats-Ewes.jpg',
    description:
      'Specifically formulated to support reproductive health and fertility in breeding goats and sheep. Contains added nutrients like omega-3 fatty acids, vitamins, and minerals to enhance breeding performance and litter size.',
    ingredientValues: [
      { title: 'Crude Protein', value: '16-18%' },
      { title: 'Crude Fat', value: '3-5%' },
      { title: 'Crude Fiber', value: '10-12%' },
      { title: 'Calcium', value: '0.8-1.0%' },
      { title: 'Phosphorus', value: '0.6-0.8%' },
    ],
  },
  {
    id: 'Lactation-Feed-Nursing-Does-Ewes',
    type: 'goat/sheep',
    name: 'Lactation Feed for Nursing Does/Ewes',
    image: '../assets/images/products/horses/Lactation-Feed-Nursing-Does-Ewes.jpg',
    description:
      'Tailored to meet the increased nutritional demands of lactating does and ewes. Provides essential nutrients for fetal development, milk production, and maternal health during the lactation period.',
    ingredientValues: [
      { title: 'Crude Protein', value: '16-18%' },
      { title: 'Crude Fat', value: '3-5%' },
      { title: 'Crude Fiber', value: '12-14%' },
      { title: 'Calcium', value: '0.8-1.0%' },
      { title: 'Phosphorus', value: '0.6-0.8%' },
    ],
  },
  {
    id: 'Mineral-Supplements-Goats-Sheep',
    type: 'goat/sheep',
    name: 'Mineral Supplements',
    image: '../assets/images/products/horses/Mineral-Supplements-Goats-Sheep.png',
    description:
      'Essential mineral supplements formulated to meet the specific requirements of goats and sheep. Provides key minerals like calcium, phosphorus, magnesium, and trace minerals to support overall health and productivity.',
    ingredientValues: [
      { title: 'Calcium', value: '15-20%' },
      { title: 'Phosphorus', value: '8-10%' },
      { title: 'Magnesium', value: '2-4%' },
      { title: 'Copper', value: '500-800 ppm' },
      { title: 'Zinc', value: '1000-1500 ppm' },
    ],
  },
  {
    id: 'Vegetarian-Dog-Food-Formula',
    type: 'dog/cat',
    name: 'Vegetarian Dog Food Formula',
    image: '../assets/images/products/dog/himalyan chuu.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Brown Rice', value: 'Included' },
      { title: 'Oats', value: 'Included' },
      { title: 'Peas', value: 'Included' },
      { title: 'Lentils', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Flaxseed', value: 'Included' },
      { title: 'Vegetable Broth', value: 'Included' },
      { title: 'Canola Oil', value: 'Included' },
      { title: 'Dried Kelp', value: 'Included' },
      { title: 'Calcium Carbonate', value: 'Included' },
    ],
  },
  {
    id: 'Vegetarian-Poultry-Feed-Pellets',
    type: 'poultry',
    name: 'Vegetarian Poultry Feed Pellets',
    image: '../assets/images/products/poultry/poultry1.jpg',
    description:
      'A complete and balanced vegetarian diet formulated to meet the nutritional requirements of poultry, including chickens, ducks, and turkeys. Made with a blend of plant-based ingredients to support optimal growth, egg production, and overall health.',
    ingredientValues: [
      { title: 'Soybean Meal', value: 'Included' },
      { title: 'Corn Gluten Meal', value: 'Included' },
      { title: 'Wheat Bran', value: 'Included' },
      { title: 'Rice Bran', value: 'Included' },
      { title: 'Sunflower Meal', value: 'Included' },
      { title: 'Pea Protein', value: 'Included' },
      { title: 'Soybean Oil', value: 'Included' },
      { title: 'Calcium Carbonate', value: 'Included' },
      { title: 'Dicalcium Phosphate', value: 'Included' },
      { title: 'Salt', value: 'Included' },
      { title: 'Vitamins', value: 'Included' },
      { title: 'Minerals', value: 'Included' },
    ],
  },
  {
    id: 'Dairy-Dog-Treats',
    type: 'dog/cat',
    name: 'Dairy Dog Treats',
    image: '../assets/images/products/dog/dog2.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Milk', value: 'Included' },
      { title: 'Cheese', value: 'Included' },
      { title: 'Whole Wheat Flour', value: 'Included' },
      { title: 'Oats', value: 'Included' },
      { title: 'Eggs', value: 'Included' },
      { title: 'Honey', value: 'Included' },
      { title: 'Baking Powder', value: 'Included' },
    ],
  },
  {
    id: 'Vegetarian-Dog-Biscuits',
    type: 'dog/cat',
    name: 'Vegetarian Dog Biscuits',
    image: '../assets/images/products/dog/dog3.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Whole Wheat Flour', value: 'Included' },
      { title: 'Brown Rice Flour', value: 'Included' },
      { title: 'Rolled Oats', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Spinach', value: 'Included' },
      { title: 'Parsley', value: 'Included' },
      { title: 'Vegetable Broth', value: 'Included' },
      { title: 'Flaxseed', value: 'Included' },
      { title: 'Eggs', value: 'Included' },
    ],
  },
  {
    id: 'Cheese-Vegetable-Stuffed-Chew-Toys',
    type: 'dog/cat',
    name: 'Cheese and Vegetable Stuffed Chew Toys',
    image: '../assets/images/products/dog/dog4.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Cheese', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Green Beans', value: 'Included' },
      { title: 'Peas', value: 'Included' },
    ],
  },
  {
    id: 'Cheese-Vegetable-Stuffed-Chew-Toys',
    type: 'dog/cat',
    name: 'Bone',
    image: '../assets/images/products/dog/Bone-Copy.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Cheese', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Green Beans', value: 'Included' },
      { title: 'Peas', value: 'Included' },
    ],
  },
  {
    id: 'Cheese-Vegetable-Stuffed-Chew-Toys',
    type: 'dog/cat',
    name: 'Bone 2.0',
    image: '../assets/images/products/dog/Bone2.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Cheese', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Green Beans', value: 'Included' },
      { title: 'Peas', value: 'Included' },
    ],
  },
  {
    id: 'Cheese-Vegetable-Stuffed-Chew-Toys',
    type: 'dog/cat',
    name: 'Fishcat',
    image: '../assets/images/products/dog/Fishcat - Copy.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Cheese', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Green Beans', value: 'Included' },
      { title: 'Peas', value: 'Included' },
    ],
  },
  {
    id: 'Cheese-Vegetable-Stuffed-Chew-Toys',
    type: 'dog/cat',
    name: 'G-snack',
    image: '../assets/images/products/dog/G-snack.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Cheese', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Green Beans', value: 'Included' },
      { title: 'Peas', value: 'Included' },
    ],
  },
  {
    id: 'Cheese-Vegetable-Stuffed-Chew-Toys',
    type: 'dog/cat',
    name: 'Gingerbread man',
    image: '../assets/images/products/dog/Gingerbread man.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Cheese', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Green Beans', value: 'Included' },
      { title: 'Peas', value: 'Included' },
    ],
  },
  {
    id: 'Cheese-Vegetable-Stuffed-Chew-Toys',
    type: 'dog/cat',
    name: 'Rice Strip & Cracker',
    image: '../assets/images/products/dog/Rice Strip & Cracker.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Cheese', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Green Beans', value: 'Included' },
      { title: 'Peas', value: 'Included' },
    ],
  },
  {
    id: 'Cheese-Vegetable-Stuffed-Chew-Toys',
    type: 'dog/cat',
    name: 'Star',
    image: '../assets/images/products/dog/Star.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Cheese', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Green Beans', value: 'Included' },
      { title: 'Peas', value: 'Included' },
    ],
  },
  {
    id: 'Cheese-Vegetable-Stuffed-Chew-Toys',
    type: 'dog/cat',
    name: 'Toothbrush',
    image: '../assets/images/products/dog/Toothbrush.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Cheese', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Green Beans', value: 'Included' },
      { title: 'Peas', value: 'Included' },
    ],
  },
  {
    id: 'Cheese-Vegetable-Stuffed-Chew-Toys',
    type: 'dog/cat',
    name: 'Turtle',
    image: '../assets/images/products/dog/Turtle.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Cheese', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Green Beans', value: 'Included' },
      { title: 'Peas', value: 'Included' },
    ],
  },
  {
    id: 'Cheese-Vegetable-Stuffed-Chew-Toys',
    type: 'dog/cat',
    name: 'Bunny',
    image: '../assets/images/products/dog/Bunny - Copy.jpg',
    description:
      'Maize starch, glycerin, powdered cellulose fiber, soy lecithin, potato starch, yeast, malt extract, beetroot extract (red products), Beta-Carotene (orange products); Safflower (yellow products), Calcium Carbonate (white products)',
    ingredientValues: [
      { title: 'Cheese', value: 'Included' },
      { title: 'Carrots', value: 'Included' },
      { title: 'Sweet Potatoes', value: 'Included' },
      { title: 'Green Beans', value: 'Included' },
      { title: 'Peas', value: 'Included' },
    ],
  },
  {
    id: 'Dairy-Based-Poultry-Feed-Crumbles',
    type: 'poultry',
    name: 'Dairy-Based Poultry Feed Crumbles',
    image: '../assets/images/products/poultry/poultry2.jpg',
    description:
      'Nutrient-rich crumbles made with dairy-derived ingredients to enhance the diet of poultry with essential proteins, vitamins, and minerals. Suitable for layers, broilers, and backyard flocks.',
    ingredientValues: [
      { title: 'Dried Whey', value: 'Included' },
      { title: 'Dried Skim Milk', value: 'Included' },
      { title: 'Dried Buttermilk', value: 'Included' },
      { title: 'Soybean Meal', value: 'Included' },
      { title: 'Corn', value: 'Included' },
      { title: 'Wheat', value: 'Included' },
      { title: 'Vegetable Oil', value: 'Included' },
      { title: 'Limestone', value: 'Included' },
      { title: 'Dicalcium Phosphate', value: 'Included' },
      { title: 'Salt', value: 'Included' },
      { title: 'Vitamin and Mineral Premix', value: 'Included' },
    ],
  },
  {
    id: 'Oil-Decake-Poultry-Feed-Mash',
    type: 'poultry',
    name: 'Oil Decake Poultry Feed Mash',
    image: '../assets/images/products/poultry/poultry3.jpg',
    description:
      "High-energy poultry feed mash made from oilseed decake, a byproduct of oil extraction, providing a rich source of protein, fiber, and essential fatty acids for poultry's growth and performance.",
    ingredientValues: [
      { title: 'Soybean Decake', value: 'Included' },
      { title: 'Sunflower Decake', value: 'Included' },
      { title: 'Cottonseed Decake', value: 'Included' },
      { title: 'Corn', value: 'Included' },
      { title: 'Wheat', value: 'Included' },
      { title: 'Rice Bran', value: 'Included' },
      { title: 'Soybean Oil', value: 'Included' },
      { title: 'Limestone', value: 'Included' },
      { title: 'Monocalcium Phosphate', value: 'Included' },
      { title: 'Salt', value: 'Included' },
      { title: 'Vitamin and Mineral Supplement', value: 'Included' },
    ],
  },
  {
    id: 'organic-fertilizer',
    name: 'Banana Peel Powder ',
    image: '../assets/images/products/horses/banana-powder.jpg',
    description:
      'A comprehensive COA provides detailed insights into the nutritional profile and quality of banana peel powder. Here is a typical analysis.',
    benefits: [
      {
        title: 'Potassium (K)',
        description: 'Crucial for plant growth, enhances flower and fruit production.',
      },
      {
        title: 'Phosphorus (P)',
        description: 'Important for root development and energy transfer.',
      },
      {
        title: 'Calcium (Ca)',
        description: 'Strengthens plant cell walls and helps in nutrient absorption',
      },
      {
        title: 'Magnesium (Mg)',
        description: 'Essential for photosynthesis as it is a core component of chlorophyll.',
      },
      {
        title: 'Nitrogen (N)',
        description: 'Vital for leafy growth',
      },
      {
        title: 'Organic Matter',
        description: 'Adds organic material to the soil, improving its structure and moisture retention',
      },
      {
        title: 'Microbial Activity',
        description: 'Enhances the activity of beneficial microbes, which break down organic material and release nutrients.',
      },
      {
        title: 'Disease Resistance',
        description: 'Strengthens plants against common diseases and pests',
      },
      {
        title: 'Root Growth',
        description: ': The potassium and phosphorus content supports robust root systems.',
      },
      {
        title: 'Sustainability',
        description: 'Reduces waste by repurposing banana peels',
      },
      {
        title: 'Environmentally Safe',
        description: 'Does not contribute to chemical runoff or soil degradation.',
      },
    ],
    uses: [
      { description: 'Mix the powder into the soil around plants to improve nutrient availability.' },
      { description: 'Add to compost piles to accelerate decomposition and enhance nutrient content.' },
      { description: 'Steep 1 kg of powder in 200 liters of water for 24-48 hours. Strain and use the liquid to water plants.' },
      { description: 'Spread the powder around the base of plants to retain soil moisture and provide a slow-release fertilizer as it decomposes' },
    ],
    specifications: [{ title: 'Nutritional Value', description: 'Rich in beta-carotene (Vitamin A precursor), fiber, and antioxidants.' }],
    ingredientValues: [
      { title: 'Moisture Content', value: '5-10%' },
      { title: 'pH Level', value: '5.5-7.5' },
      { title: 'Potassium (K)', value: '30-40%' },
      { title: 'Calcium (Ca) ', value: '10-15%' },
      { title: 'Magnesium (Mg)', value: '2-5%' },
      { title: 'Nitrogen (N) ', value: '0.5-1.5%' },
      { title: 'Organic Matter', value: '70-80%' },
      { title: 'Manganese (Mn)', value: '0.1-0.3%' },
      { title: 'Zinc (Zn)', value: '0.05-0.15%' },
      { title: 'Iron (Fe)', value: '0.1-0.5%' },
      { title: 'Lead (Pb)', value: '< 5 ppm' },
      { title: 'Cadmium (Cd)', value: '< 1 ppm' },
      { title: 'Pathogens', value: 'Absent' },
    ],
    feedingInstruction: [
      { description: 'Feed 1-2 lbs per head per day.' },
      { description: 'Offer dehydrated carrots as part of the daily feed ration.' },
      { description: 'Ensure cattle have access to clean, fresh water at all times.' },
      { description: 'Monitor for any signs of digestive issues or changes in appetite.' },
    ],
    type: 'organic-fertilizer',
  },
];
