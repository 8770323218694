import React from 'react';
import Header from '../components/Husk-n-spices/Header';
import ListItem from '../components/ListItem';
import { data } from '../HusknSpicesData/ProductFeedGradeVegetableAndGrainPowder';
import Footer from '../components/Husk-n-spices/Footer';

export default function PeelPowder() {
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      {data
        .filter(i => i.type === 'PeelPowder')
        .map(peelpowder => {
          return (
            <ListItem
              name={peelpowder.name || 'No Name'}
              description={peelpowder.description || 'No Description'}
              ingredientValues={peelpowder.ingredientValues || 'No Ingredient Values'}
              image={peelpowder.image}
              id={peelpowder.id}
            />
          );
        })}
      <Footer />
    </div>
  );
}
