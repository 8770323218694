import React from 'react';
import Header from '../components/Husk-n-spices/Header';
import HusknSpicesHome from './HusknSpicesHome';

export default function HusknSpice() {
  return (
    <>
      <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
        <Header />
        <HusknSpicesHome />
      </div>
    </>
  );
}
