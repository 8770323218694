import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import brochurePDF from '../../components/pdf/brochure.pdf';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('');
  const [hoveredProduct, setHoveredProduct] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavItemClick = path => {
    setActiveItem(path);
    setMenuOpen(false);
    navigate(path);
  };

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  const handleProductHover = product => {
    setHoveredProduct(product);
  };

  const handleProductLeave = () => {
    setHoveredProduct('');
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setIsMobile(window.innerWidth <= 768);
      if (!isMobile) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  const navbarStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: isMobile ? 'center' : 'flex-end',
    width: '100%',
  };

  const logoWidth = width > 768 ? 150 : 100;

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header id="navbar-spy" className="header header-1 header-8 header-light">
      <nav id="primary-menu" className="navbar navbar-expand-lg navbar-light logo-centered">
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <img className="logo logo-margin-top" src="assets/images/logo/EI.png" alt="logo" style={{ width: logoWidth, marginTop: '42px' }} />{' '}
          <div style={{ display: 'flex', alignItems: 'center', backgroundColor: menuOpen ? '#ffffff' : 'transparent' }}>
            {isMobile && (
              <button
                onClick={toggleMenu}
                style={{
                  marginRight: '10px',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '5px 10px',
                  backgroundColor: '#ffffff',
                  borderRadius: '5px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                <i className={`fas ${menuOpen ? 'fa-times' : 'fa-bars'}`} style={{ color: '#333333', fontSize: '20px' }}></i>
              </button>
            )}
            <ul className="navbar-nav mr-auto ml-auto" style={{ ...navbarStyle, display: isMobile ? (menuOpen ? 'block' : 'none') : 'flex' }}>
              <li>
                <a
                  style={{
                    cursor: 'pointer',
                    color: activeItem === '/husk-n-spices' ? '#7B0323' : 'inherit',
                  }}
                  onClick={() => handleNavItemClick('/husk-n-spices')}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  style={{
                    cursor: 'pointer',
                    color: activeItem === '/husknspicesabout' ? '#7B0323' : 'inherit',
                  }}
                  onClick={() => handleNavItemClick('/husknspicesabout')}
                >
                  About us
                </a>
              </li>
              <li
                className={`has-dropdown ${hoveredProduct === 'products' ? 'show' : ''}`}
                onMouseEnter={() => handleProductHover('products')}
                onMouseLeave={handleProductLeave}
              >
                <a
                  style={{
                    cursor: 'pointer',
                    color: isOpen ? '#7B0323' : 'inherit',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className="dropdown-toggle link-hover"
                  data-hover="shop"
                  onClick={handleToggle}
                >
                  Products
                  <span
                    style={{
                      marginLeft: '10px',
                      fontSize: '18px',
                      transition: 'transform 0.3s',
                    }}
                  >
                    <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                  </span>
                </a>

                {isOpen && (
                  <ul className="dropdown-menu" style={{ display: hoveredProduct === 'products' ? 'block' : 'none', width: '350px' }}>
                    <li>
                      <a
                        style={{ cursor: 'pointer', color: activeItem === '/spice' ? '#7B0323' : 'inherit' }}
                        onClick={() => handleNavItemClick('/spice')}
                      >
                        Spice
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: 'pointer', color: activeItem === '/dehydrated-vegetable-flakes-and-cube' ? '#7B0323' : 'inherit' }}
                        onClick={() => handleNavItemClick('/dehydrated-vegetable-flakes-and-cube')}
                      >
                        Dehydrated Vegetable Flakes and cube
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: 'pointer', color: activeItem === '/dehydrated-fruits-powder' ? '#7B0323' : 'inherit' }}
                        onClick={() => handleNavItemClick('/dehydrated-fruits-powder')}
                      >
                        Dehydrated Fruits Flakes And Powder
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: 'pointer', color: activeItem === '/spray-drayed-vegetable-powder' ? '#7B0323' : 'inherit' }}
                        onClick={() => handleNavItemClick('/spray-drayed-vegetable-powder')}
                      >
                        Spray Drayed Vegetable Powder
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: 'pointer', color: activeItem === '/spray-drayed-fruits-powder' ? '#7B0323' : 'inherit' }}
                        onClick={() => handleNavItemClick('/spray-drayed-fruits-powder')}
                      >
                        Spray Drayed Fruits Powder
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: 'pointer', color: activeItem === '/herbal-products' ? '#7B0323' : 'inherit' }}
                        onClick={() => handleNavItemClick('/herbal-products')}
                      >
                        Herbal Products
                      </a>
                    </li>

                    <li>
                      <a
                        style={{ cursor: 'pointer', color: activeItem === '/peel-powder' ? '#7B0323' : 'inherit' }}
                        onClick={() => handleNavItemClick('/peel-powder')}
                      >
                        Peel Powder (Skin Glowing Powder)
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: 'pointer', color: activeItem === '/feed-grade-vegetable-and-grain-powder' ? '#7B0323' : 'inherit' }}
                        onClick={() => handleNavItemClick('/feed-grade-vegetable-and-grain-powder')}
                      >
                        Feed Grade Vegetable and Grain Powder
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: 'pointer', color: activeItem === '/dehydrated-vegetable-powder' ? '#7B0323' : 'inherit' }}
                        onClick={() => handleNavItemClick('/dehydrated-vegetable-powder')}
                      >
                        Dehydrated Vegetable Powder
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              {/* <li>
                <a
                  style={{
                    cursor: 'pointer',
                    color: activeItem === '/about-us' ? '#7B0323' : 'inherit',
                  }}
                  onClick={() => handleNavItemClick('/OrganicFertilizer')}
                >
                  Organic Fertilizer{' '}
                </a>
              </li> */}
              <li>
                <a
                  style={{
                    cursor: 'pointer',
                    color: activeItem === '/special-education' ? '#7B0323' : 'inherit',
                  }}
                  className="dropdown-toggle menu-item"
                  target="_blank"
                  href="https://industry.siliconindia.com/vendor/edge-infiniumhusk--n-spices-revolution-the-dehydrated-food-market-globally-cid-15932.html"
                >
                  Special Education
                </a>
              </li>
              <li>
                <a
                  style={{
                    cursor: 'pointer',
                    color: activeItem === '/husknspicescontact' ? '#7B0323' : 'inherit',
                  }}
                  onClick={() => handleNavItemClick('/husknspicescontact')}
                  data-hover="pages"
                >
                  Contact Us
                </a>
              </li>
              <li>
                <div
                  style={{
                    background: '#9a2a3a',
                    padding: '10px 20px',
                    color: 'white',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    textAlign: 'center',
                    margin: isMobile ? '10px 120px 5px 0' : '10px 0',
                  }}
                  onClick={() => window.open(brochurePDF, '_blank')}
                >
                  Download Brochure
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
