import React from 'react';
import Header from '../components/Husk-n-spices/Header';
import ListItem from '../components/ListItem';
import { data } from '../HusknSpicesData/ProductFeedGradeVegetableAndGrainPowder';
import Footer from '../components/Husk-n-spices/Footer';

export default function SprayDrayedFruitsPowder() {
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      {data
        .filter(i => i.type === 'SprayDrayedFruitsPowder')
        .map(spraydrayedfruitspowder => {
          console.log('spraydrayedfruitspowder.id', spraydrayedfruitspowder.id);
          return (
            <ListItem
              name={spraydrayedfruitspowder.name || 'No Name'}
              description={spraydrayedfruitspowder.description || 'No Description'}
              ingredientValues={spraydrayedfruitspowder.ingredientValues || 'No Ingredient Values'}
              image={spraydrayedfruitspowder.image}
              id={spraydrayedfruitspowder.id}
            />
          );
        })}
      <Footer />
    </div>
  );
}
