import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();
  const [showPage, setShowPage] = useState(false);

  return (
    <>
      <video
        style={{
          position: 'absolute',
          height: '100vh',
          width: '100vw',
          objectFit: 'cover',
        }}
        id="background-video"
        autoPlay
        muted
        onPlay={() => {
          // increase payback speed
          document.getElementById('background-video').playbackRate = 3;
        }}
        onEnded={() => {
          setShowPage(true);
          // document.getElementById('content').style.display = 'block';
        }}
        // onended="showContent()"
      >
        <source src="./assets/images/video/EDGE INFINIUM 04.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div id="content" style={{ display: !showPage ? 'none' : 'block' }}>
        <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
          <header id="navbar-spy1" className="header header-4 header-light d-none d-lg-block">
            <nav className="navbar logo-centered">
              <div className="container">
                <div className="navbar-brand" style={{ paddingBottom: 30, paddingTop: 30 }}>
                  <img className="logo" style={{ height: 100 }} src="assets/images/logo/EI.png" alt="logo" />
                </div>
              </div>
            </nav>
          </header>

          <section id="products-carousel2" className="products-carousel products-carousel-2 pt-0 pb-0">
            <div className="container-fluid pr-0 pl-0 pt-5">
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-3">
                  <a href="" onClick={() => navigate('/husk-n-spices')}>
                    <div className="product-item">
                      <div className="product--img">
                        <img src="assets/images/products/carousel/4.jpg" alt="Portfolio Item" />
                      </div>
                      {/* .product-img end */}
                      <div className="product--title" style={{ textAlign: 'center' }}>
                        <img
                          src="assets/images/logo/EI.png"
                          alt="Company Logo"
                          className="footer-img"
                          style={{
                            maxWidth: '150px',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            padding: '5px',
                            display: 'block',
                            margin: '0 auto',
                          }}
                        />
                        <h3 style={{ color: '#fff', margin: '0', textAlign: 'center' }}>Husk 'n' Spices</h3>
                      </div>
                      {/* .product-title end */}
                      <div className="product--hover">
                        <div className="product--action">
                          <div className="product--action-content">
                            <h3 style={{ color: '#fff' }}>Husk 'n' Spices</h3>
                          </div>
                        </div>
                      </div>
                      {/* .product-hover end */}
                    </div>
                  </a>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-3">
                  <a href="" onClick={() => navigate('/cattle-feed')}>
                    <div className="product-item">
                      <div className="product--img">
                        <img src="assets/images/products/carousel/1.jpg" alt="Portfolio Item" />
                      </div>

                      {/* .product-img end */}
                      <div className="product--title" style={{ textAlign: 'center' }}>
                        <img
                          src="assets/images/logo/The cattle Feed fl-01.png"
                          alt="Company Logo"
                          className="footer-img"
                          style={{
                            maxWidth: '150px',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            padding: '5px',
                            display: 'block',
                            margin: '0 auto',
                          }}
                        />
                        <h3 style={{ color: '#fff', margin: '10px 0', textAlign: 'center' }}>
                          The Cattle Feed <br />
                          The Animal Feed
                        </h3>
                      </div>

                      {/* .product-title end */}
                      <div className="product--hover">
                        <div className="product--action">
                          <div className="product--action-content">
                            <h3 style={{ color: '#fff' }}>
                              <br />
                              The Cattle Feed
                              <br />
                              The Animal Feed
                            </h3>
                          </div>
                        </div>
                      </div>
                      {/* .product-hover end */}
                    </div>
                  </a>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-3">
                  <div className="product-item">
                    <div className="product--img">
                      <img src="assets/images/products/carousel/2.jpg" alt="Portfolio Item" />
                    </div>
                    <div className="product--title">
                      <h3>
                        <a href="product-gallery-vertical.html">
                          <br />
                          Coming Soon
                        </a>
                      </h3>
                    </div>
                    <div className="product--hover">
                      <div className="product--action">
                        <div className="product--action-content">
                          <h3 style={{ color: '#fff' }}>
                            <br />
                            Coming Soon
                          </h3>
                          <p>we're responsible for process and Finall results</p>
                          <a href="#" className="btn btn--underlined">
                            SHOP NOW
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-3">
                  <div className="product-item">
                    <div className="product--img">
                      <img src="assets/images/products/carousel/2.jpg" alt="Portfolio Item" />
                    </div>
                    <div className="product--title">
                      <h3>
                        <a href="product-gallery-vertical.html">
                          <br />
                          Coming Soon
                        </a>
                      </h3>
                    </div>
                    <div className="product--hover">
                      <div className="product--action">
                        <div className="product--action-content">
                          <h3 style={{ color: '#fff' }}>Coming Soon</h3>
                          <p>we're responsible for process and Finall results</p>
                          <a href="#" className="btn btn--underlined">
                            SHOP NOW
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
