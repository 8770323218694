import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import CattleFeed from './pages/CattleFeedRoute';
import Product from './pages/Product';
import List from './pages/List';
import HorseList from './pages/Horse-List';
import AboutUs from './pages/About-Us';
import ContactUs from './pages/Contact-us';
import CowList from './pages/Cow-List';
import PorkList from './pages/Pork-List';
import GoatAndSheepList from './pages/Goat-And-Sheep-List';
import DogCatList from './pages/Dog-List';
import PoultryList from './pages/Poultry-List';
import OrganicFertilizer from './pages/Organic-Fertilizer';
import HusknSpice from './pages/HusknSpiceRoute';
import HusknSpicesAbout from './pages/HusknSpicesAbout';
import HusknSpicesContact from './pages/Husknspicescontact';
import VegetablePowder from './pages/Vegetablepowder';
import PeelPowder from './pages/PeelPowder';
import DehydratedVegetablePowder from './pages/DehydratedVegetablePowder';
import SprayDrayedFruitsPowder from './pages/SprayDrayedFruitsPowder';
import HerbalProducts from './pages/HerbalProducts';
import Spice from './pages/Spice';
import DehydratedFruitsPowder from './pages/DehydratedFruitsPowder';
import SprayDrayedVegetablePowder from './pages/SprayDrayedVegetablePowder';
import DehydratedVegetableFlakesAndCube from './pages/DehydratedVegetableFlakesAndCube';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Home />}></Route>
      <Route path="cattle-feed" element={<CattleFeed />} />
      <Route path="husk-n-spices" element={<HusknSpice />} />
      <Route path="list" element={<List />} />
      <Route path="cow-feed-produts" element={<CowList />} />
      <Route path="pork-feed-products" element={<PorkList />} />
      <Route path="organicfertilizer" element={<OrganicFertilizer />} />
      <Route path="horse-feed-products" element={<HorseList />} />
      <Route path="goat-and-sheep-feed-products" element={<GoatAndSheepList />} />
      <Route path="dog-cat-products" element={<DogCatList />} />
      <Route path="poultry-products" element={<PoultryList />} />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path=":pId" element={<Product />} />
      <Route path="husknspicesabout" element={<HusknSpicesAbout />} />
      <Route path="husknspicescontact" element={<HusknSpicesContact />} />
      <Route path="feed-grade-vegetable-and-grain-powder" element={<VegetablePowder />} />
      <Route path="peel-powder" element={<PeelPowder />} />
      <Route path="dehydrated-vegetable-powder" element={<DehydratedVegetablePowder />} />
      <Route path="spray-drayed-fruits-powder" element={<SprayDrayedFruitsPowder />} />
      <Route path="herbal-products" element={<HerbalProducts />} />
      <Route path="spice" element={<Spice />} />
      <Route path="dehydrated-fruits-powder" element={<DehydratedFruitsPowder />} />
      <Route path="spray-drayed-vegetable-powder" element={<SprayDrayedVegetablePowder />} />
      <Route path="dehydrated-vegetable-flakes-and-cube" element={<DehydratedVegetableFlakesAndCube />} />
    </>,
  ),
);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
