import React from 'react';
import Header from '../components/Husk-n-spices/Header';
import ListItem from '../components/ListItem';
import { data } from '../HusknSpicesData/ProductFeedGradeVegetableAndGrainPowder';
import Footer from '../components/Husk-n-spices/Footer';

export default function DehydratedVegetableFlakesAndCube() {
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      {data
        .filter(i => i.type === 'DehydratedVegetableFlakesAndCube')
        .map(dehydratedvegetableflakesandcube => {
          return (
            <ListItem
              name={dehydratedvegetableflakesandcube.name || 'No Name'}
              description={dehydratedvegetableflakesandcube.description || 'No Description'}
              ingredientValues={dehydratedvegetableflakesandcube.ingredientValues || 'No Ingredient Values'}
              image={dehydratedvegetableflakesandcube.image}
              id={dehydratedvegetableflakesandcube.id}
            />
          );
        })}
      <Footer />
    </div>
  );
}
