import React from 'react';
import Header from '../components/Cattle-feed/Header';
import ListItem from '../components/ListItem';

export default function List() {
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      {/* <ListItem
        name={'Beat'}
        calcium={'a'}
        description={'New line'}
        energy={'5000 kl'}
        fiber={'123'}
        id={'BNAS'}
        phosphorus={'asd'}
        protein={'213'}
        image={'../assets/images/products/Dehydrated-Carrots.jpg'}
      /> */}
    </div>
  );
}
