import React from 'react';
import Header from '../components/Husk-n-spices/Header';
import Footer from '../components/Husk-n-spices/Footer';

export default function Aboutus() {
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      <div>
        <header>
          <h1 style={{ color: '#7B0323', fontSize: '50px' }}>About Us</h1>
        </header>
        <div className="timeline-container">
          <section className="timeline">
            <article>
              <div className="icon">&#127838;</div>
              <div className="content">
                <div style={{ fontSize: '24px' }}>
                  Welcome to EDGE INFINIUM, the driving force behind the distinguished brand HUSK N SPICES. Since our inception in 2007, we have been
                  committed to excellence and innovation in the spice industry, setting benchmarks for quality and flavor. Our journey began with a
                  deep passion for spices, and this passion has propelled us to expand our horizons and diversify our offerings.
                </div>
              </div>
            </article>
            <article>
              <div className="icon">&#127813;</div>
              <div className="content">
                <div style={{ fontSize: '24px' }}>
                  In 2009, we took a significant step forward by venturing into the food dehydration industry, introducing dehydrated vegetables and
                  fruits to our portfolio. This strategic move marked the beginning of a new chapter for EDGE INFINIUM, enabling us to cater to the
                  growing demand for high-quality, nutritious, and convenient food products.
                </div>
              </div>
            </article>
          </section>
          <div className="image-container">
            <img src="assets/images/about/gallery/Spices.jpg" alt="Edge Infinium" />
          </div>
        </div>
      </div>
      <div>
        <header>
          <h1 style={{ color: '#7B0323', fontSize: '50px' }}>Husk 'n' Spices Products and Services</h1>
        </header>
        <div className="image-container">
          <img
            style={{ marginTop: '80px', height: '551px', width: '1000px', padding: '12px' }}
            src="assets/images/about/gallery/spices1.webp"
            alt="Cattle Feed"
          />
        </div>
        <section className="animal-feed">
          <h2 className="animalfeed" style={{ fontSize: '50px', color: '#7B0323' }}>
            Husk 'n' Spices
          </h2>
          <div className="feed-grid">
            <div className="feed-item">
              <div className="icon">🍎</div>
              <h3 style={{ color: '#7B0323' }}>Fresh & Hygienic</h3>
              <p>All our products are natural, hygienically packed, and free from any chemical preservatives.</p>
            </div>
            <div className="feed-item">
              <div className="icon">⭐</div>
              <h3 style={{ color: '#7B0323' }}>Premium Quality</h3>
              <p>Our well-equipped labs follow globally accepted safety and hygienic norms, offering premium quality.</p>
            </div>
            <div className="feed-item">
              <div className="icon">🍽️</div>
              <h3 style={{ color: '#7B0323' }}>Food Safety</h3>
              <p>Our well-equipped labs follow globally accepted safety and hygienic norms, offering premium quality.</p>
            </div>
            <div className="feed-item">
              <div className="icon">🥕</div>
              <h3 style={{ color: '#7B0323' }}>Dehydrated Vegetable Flakes and Powder</h3>
              <p>Perfect for enhancing the flavor and nutritional value of various culinary creations.</p>
            </div>
            <div className="feed-item">
              <div className="icon">🍋</div>
              <h3 style={{ color: '#7B0323' }}>Dehydrated Fruit Flakes and Powder</h3>
              <p>Ideal for a variety of applications, from baking to beverages, offering natural sweetness and health benefits.</p>
            </div>
            <div className="feed-item">
              <div className="icon">🌶️</div>
              <h3 style={{ color: '#7B0323' }}>Spray Dried Vegetable Powder</h3>
              <p>A versatile ingredient for soups, sauces, and snacks, ensuring a rich taste and vibrant color.</p>
            </div>
          </div>

          <br />
          <p>Husk 'n' Spices Products enjoy excellent Reputation in domestic and international markets for their quality products such as.</p>
        </section>
      </div>
      <div className="feed-grid">
        <div className="feed-item">
          <div className="icon">🍏</div>
          <h3 style={{ color: '#7B0323' }}>Spray Dried Fruit Powder</h3>
          <p>A convenient way to add the essence of fruits to any recipe, maintaining the integrity of flavor and nutrition.</p>
        </div>
        <div className="feed-item">
          <div className="icon">🧂</div>
          <h3 style={{ color: '#7B0323' }}>Seasoning</h3>
          <p>Expertly crafted blends to elevate the taste of any dish, delivering consistency and excellence.</p>
        </div>
        <div className="feed-item">
          <div className="icon">🎨</div>
          <h3 style={{ color: '#7B0323' }}>Caramel Food Colours</h3>
          <p>High-quality food coloring solutions that enhance the visual appeal of food and beverages.</p>
        </div>
        <div className="feed-item">
          <div className="icon">🌿</div>
          <h3 style={{ color: '#7B0323' }}>Herbal Products</h3>
          <p>Natural and pure herbal ingredients for health and wellness applications.</p>
        </div>
        <div className="feed-item">
          <div className="icon">♻️</div>
          <h3 style={{ color: '#7B0323' }}>Peel Powder</h3>
          <p>Nutrient-rich powders made from fruit and vegetable peels, promoting sustainability and health.</p>
        </div>
        <div className="feed-item">
          <div className="icon">🍗</div>
          <h3 style={{ color: '#7B0323' }}>Feed Grade</h3>
          <p>Premium quality products designed to meet the nutritional needs of livestock and poultry.</p>
        </div>
      </div>

      <style jsx>{`
        .feed-grid2 {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          gap: 20px;
          width: 100%;
        }

        .feed-item2 {
          background: #fff;
          padding: 20px;
          border: 1px solid #ddd;
          border-radius: 8px;
          text-align: center;
        }

        .icon {
          font-size: 2rem;
          margin-bottom: 10px;
        }

        h3 {
          margin-top: 0;
          margin-bottom: 10px;
        }

        p {
          margin: 0;
        }
      `}</style>

      <div>
        <section className="dehydrated-products">
          <article>
            <div className="content">
              <h2 style={{ color: '#7B0323', fontSize: '50px' }}>Global Reach</h2>
              <p style={{ fontSize: '22px' }}>
                With offices in the USA and Europe, Husk 'n' Spices has a strong international presence. We export our products to western countries,
                providing full solutions for animal husbandry and farming. Our global network ensures that we can meet the needs of customers around
                the world, delivering quality products and exceptional service.
              </p>
            </div>
          </article>
        </section>
      </div>
      <div>
        <section style={{ fontSize: '50px', marginTop: '60px' }} className="commitment">
          <div className="container">
            <h1 className="commitment-title">Our Commitment</h1>
            <div>
              <div style={{ color: 'black', fontSize: '22px' }}>Spices</div>
            </div>
            <div>
              <div style={{ color: 'black', fontSize: '22px' }}>Dehydrated Vegetable Flakes And Powder</div>
            </div>
            <div>
              <div style={{ color: 'black', fontSize: '22px' }}>Dehydrated Fruits Flakes And Powder</div>
            </div>
            <div>
              <div style={{ color: 'black', fontSize: '22px' }}>Spray Drayed Vegetable Powder</div>
            </div>
            <div>
              <div style={{ color: 'black', fontSize: '22px' }}>Spray Drayed Fruits Powder</div>
            </div>
            <div>
              <div style={{ color: 'black', fontSize: '22px' }}>Seasoning</div>
            </div>
            <div>
              <div style={{ color: 'black', fontSize: '22px' }}>Herbel Products</div>
            </div>
            <div>
              <div style={{ color: 'black', fontSize: '22px' }}>Peel Powder</div>
            </div>
            <div>
              <div style={{ color: 'black', fontSize: '22px' }}>Feed Grade</div>
            </div>
          </div>
        </section>
        <div className="img-container2">
          <img src="assets/images/about/gallery/spices3.jpeg" />
        </div>
      </div>
      <div className="visionhead">
        <section>
          <div className="vision-mission-left">
            <h2 style={{ marginTop: '100px' }}>Our Vision</h2>
            <p>
              We are proud to be a trusted partner for our clients, offering customized solutions and exceptional service. Our commitment to
              sustainability and innovation drives us to continuously improve and adapt to the ever-changing needs of the global market.
            </p>
          </div>
          <div className="vision-mission-right">
            <h2 style={{ marginTop: '40px' }}>Our Mission</h2>
            <p>
              At EDGE INFINIUM, our mission is to harness the goodness of nature to provide our customers with superior quality products. Our
              state-of-the-art manufacturing facilities are equipped with advanced technology, and our rigorous quality control measures ensure that
              every product meets the highest standards of purity, safety, and taste.
            </p>
          </div>
        </section>
      </div>

      <div>
        <img className="sheepandgoat" src="assets/images/about/gallery/spices5.jpeg" alt="Cattle Feed" />
      </div>
      <div>
        <div
          className="vision-mission-right"
          style={{
            padding: '60px',
            borderRadius: '10px',
          }}
        >
          <h2
            style={{
              color: '#9a2a3a',
              textAlign: 'center',
              marginBottom: '20px',
            }}
          >
            Our Journey
          </h2>
          <p
            style={{
              color: '#555',
              lineHeight: '1.6',
              textAlign: 'center',
              margin: '0 auto',
              maxWidth: '100%',
              fontSize: '20px',
              fontWeight: '500',
            }}
          >
            Join us on our journey as we continue to lead the way in the food dehydration industry. Experience the essence of quality, innovation, and
            excellence with EDGE INFINIUM and our brand HUSK N SPICES. Explore our diverse range of products and discover how we can help you bring
            the best to your table.
          </p>
        </div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <section className="founder">
          <div className="container">
            <div className="founder-info">
              <div className="founder-image">
                <img src="assets/images/about/gallery/vijaysukla.png" alt="vijay shukla" />
              </div>
              <div className="founder-details">
                <h2>Meet Our Founder</h2>
                <p>
                  Mr. Vijay Shukla, the founder and driving force behind Edge Infinium, brings a wealth of experience and vision to the company. His
                  leadership has been instrumental in guiding the company through its various phases of growth and innovation. Under his guidance,
                  Edge Infinium continues to reach new heights, maintaining a steadfast commitment to quality and customer satisfaction.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="contact">
          <div className="container">
            <h2 style={{ color: '#7B0323', fontSize: '50px' }}>Contact Us</h2>
            <p>
              We invite you to explore our wide range of products and experience the quality and excellence that define Edge Infinium Private Limited.
              For more information about our products and services, please do not hesitate to contact us. We look forward to serving you and building
              lasting relationships.
            </p>
            <a href="contact-us" style={{ backgroundColor: '#7B0323' }} className="btn">
              Contact Now
            </a>
          </div>
        </section>
      </div>
      <section className="products-services">
        <section className="grid-section">
          <div className="container">
            <div className="center-text larger-text" style={{ color: '#7B0323', fontSize: '50px' }}>
              Our Products and Services
            </div>
            <div className="container1">
              <div className="card">
                <h2>Animal Feed</h2>
                <p>
                  Under our well-known brands, "The Cattle Feed" and "The Animal Feed," we offer a wide range of high-quality feed solutions for
                  various animals including cattle, horses, pigs, sheep, goats, dogs, cats, poultry, and fish. Our feeds are formulated to ensure
                  optimal health, growth, and productivity.
                </p>
              </div>
              <div className="card">
                <h2>Dehydrated Vegetables Powder and Flakes</h2>
                <p>
                  We produce and supply dehydrated vegetable powders and flakes, which are nutrient-rich and convenient for use in various culinary
                  applications. These products retain the essential vitamins and minerals of fresh vegetables, providing a healthy option for our
                  customers.
                </p>
              </div>
              <div className="card">
                <h2>Organic Fertilizers</h2>
                <p>
                  Committed to sustainable agriculture, we manufacture organic fertilizers from cow dung. These fertilizers enhance soil health and
                  fertility, promoting sustainable farming practices and contributing to better crop yields.
                </p>
              </div>
            </div>
            <div className="container1">
              <div className="card">
                <h2>Quality Assurance</h2>
                <p>
                  At Edge Infinium, quality is at the heart of everything we do. Our state-of-the-art manufacturing processes and rigorous quality
                  control measures ensure that our products meet the highest standards. We source the best ingredients and utilize advanced technology
                  to produce feed and fertilizers that are both effective and safe.
                </p>
              </div>
              <div className="card">
                <h2>Sustainability</h2>
                <p>
                  We are deeply committed to sustainability and environmental stewardship. Our organic fertilizers are a testament to our dedication
                  to promoting sustainable farming practices. By converting cow dung into high-quality fertilizers, we contribute to reducing waste
                  and enhancing soil health.mers.
                </p>
              </div>
              <div className="card">
                <h2>Customer Satisfaction</h2>
                <p>
                  Our customers are our top priority. We strive to understand their needs and provide solutions that exceed their expectations. Our
                  team of experts is always ready to offer support and guidance, ensuring that our customers achieve the best results with our
                  products.
                </p>
              </div>
            </div>
            <div className="container1">
              <div className="card">
                <h2>Empowering India's Marginalized Farmer Communities</h2>
                <p>
                  At Edge Infinium Private Limited, we are dedicated to making a meaningful difference in the lives of India's poor farmers and labor
                  class. Recognizing the challenges they face in securing sustainable livelihoods, we have implemented a multifaceted humanitarian
                  approach aimed at providing them with opportunities, resources, and support to thrive.
                </p>
              </div>
              <div className="card">
                <h2>Farming Jobs and Economic Opportunities</h2>
                <p>
                  We believe in the power of work to uplift communities. Through our initiatives, we offer farming jobs and various employment
                  opportunities to individuals from disadvantaged backgrounds. By engaging them in productive activities, we not only enable them to
                  earn a livelihood but also empower them with skills, dignity, and purpose.
                </p>
              </div>
              <div className="card">
                <h2>Utilizing Rented Land for Social Good</h2>
                <p>
                  Many farmers in India struggle to cultivate their land due to various reasons, including financial constraints or health issues. To
                  address this challenge, we actively engage in renting land from those who are incapable of farming themselves. By utilizing this
                  land for productive purposes, such as agriculture or community projects, we ensure that valuable resources are put to good use while
                  providing opportunities for income generation.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </section>
    </div>
  );
}
