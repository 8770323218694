import React from 'react';
import Header from '../components/Cattle-feed/Header';
import ListItem from '../components/ListItem';
import { data } from '../HorseData/ProductsForHorses';
import Footer from '../components/Cattle-feed/Footer';

export default function DogCatList() {
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      {data
        .filter(i => i.type === 'dog/cat')
        .map(dog => {
          return (
            <ListItem
              name={dog.name || 'No Name'}
              description={dog.description || 'No Description'}
              ingredientValues={dog.ingredientValues || 'No Ingredient Values'}
              image={dog.image}
              id={dog.id}
            />
          );
        })}
      <Footer />
    </div>
  );
}
