import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ListItem({ name, description, ingredientValues, id, image }) {
  const navigate = useNavigate();

  console.log('ingredientValues -> ', ingredientValues);

  const getValueByTitle = title => {
    const item = ingredientValues?.find(e => e.title === title);
    return item ? item.value : '-';
  };

  return (
    <div className="main-container pt-xl-5 pt-lg-3">
      <div className="row align-items-center">
        <li className="product-listing__item" style={{ listStyleType: 'none' }}>
          <div className="product-container">
            <a className="product-cover product-listing__cover" style={{ width: 400 }}>
              <img
                src={image}
                className="product-cover__image"
                alt="Nutrena Triumph 12-8 front of packaging"
                decoding="async"
                fetchpriority="high"
                sizes="(max-width: 768px) 100vw, 768px"
                style={{ objectFit: 'contain', objectPosition: 'center', width: 400, height: 300 }}
              />
            </a>
            <div className="product-listing__content">
              <h3 className="h3 products__title primary-color-text">
                <a href="">{name}</a>
              </h3>
              {/* PRODUCT RATING start */}
              <div className="product-rating product__rating">
                <div data-bv-show="inline_rating" data-bv-product-id={111172} data-bv-seo="false" className="product-rating__info" />
              </div>
              {/* PRODUCT RATING end */}
              {/* PRODUCT INDICATORS start */}

              {ingredientValues ? (
                <div className="product-indicators product-indicators--small">
                  <div className="product-indicators__item">
                    <span className="percentage" style={{ display: 'flex' }}>
                      {getValueByTitle('Protein')}
                    </span>
                    <span className="subhead">Protein</span>
                  </div>
                  <div className="product-indicators__item">
                    <span className="percentage">{getValueByTitle('Fiber')}</span>
                    <span className="subhead">Fiber</span>
                  </div>
                  <div className="product-indicators__item">
                    <span className="percentage">{getValueByTitle('Energy (ME)')}</span>
                    <span className="subhead">Energy (ME)</span>
                  </div>
                  <div className="product-indicators__item">
                    <span className="percentage">{getValueByTitle('Calcium')}</span>
                    <span className="subhead">Calcium</span>
                  </div>
                  <div className="product-indicators__item">
                    <span className="percentage">{getValueByTitle('Phosphorus')}</span>
                    <span className="subhead">Phosphorus</span>
                  </div>
                </div>
              ) : null}

              {/* PRODUCT INDICATORS end */}
              <p className="products__description">{description}</p>
              <a
                style={{ cursor: 'pointer' }}
                className="button button--primary products__learn-more"
                onClick={() => {
                  navigate(`/${id}`);
                }}
              >
                Learn more
              </a>
            </div>
          </div>
        </li>
      </div>
      <hr />
    </div>
  );
}
