import React from 'react';
import Header from '../components/Cattle-feed/Header';
import Footer from '../components/Cattle-feed/Footer';

export default function Aboutus() {
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      <div>
        <header>
          <h1 style={{ color: '#7B0323', fontSize: '50px' }}>About Us</h1>
        </header>
        <div className="timeline-container">
          <section className="timeline">
            <article>
              <div className="icon">&#128200;</div>
              <div className="content">
                <h2 style={{ color: '#7B0323' }}>2007 - The Inception</h2>
                <p>
                  Edge Infinium was initially conceptualized by Mr. Vijay Shukla, beginning with a focus on spices. This phase marked the foundation
                  of our commitment to quality, sourcing the best spices to deliver rich flavors and authentic experiences to our customers.
                </p>
              </div>
            </article>
            <article>
              <div className="icon">&#127815;</div>
              <div className="content">
                <h2 style={{ color: '#7B0323' }}>2009 - Diversification into Dehydrated Vegetables and Fruits</h2>
                <p>
                  Recognizing the growing demand for healthy and convenient food options, we expanded our product range to include dehydrated
                  vegetables and fruits. This strategic move allowed us to cater to health-conscious consumers seeking nutritious, long-lasting food
                  options.
                </p>
              </div>
            </article>
            <article>
              <div className="icon">&#128046;</div>
              <div className="content">
                <h2 style={{ color: '#7B0323' }}>2010 - Founding and Early Growth</h2>
                <p>
                  Edge Infinium formally commenced its journey as a proprietorship on April 1, 2010, under GST number 24BGXPS9639B1ZQ. Our focus
                  shifted towards providing premium animal feed under the brand names "The Cattle Feed" and "The Animal Feed." Our dedication to
                  quality and innovation quickly established us as a trusted name in the industry.
                </p>
              </div>
            </article>
            <article>
              <div className="icon">&#127813;</div>
              <div className="content">
                <h2 style={{ color: '#7B0323' }}>2013 - Innovation with Spray Dried Vegetables and Fruits</h2>
                <p>
                  Continuing our tradition of innovation, we introduced spray-dried vegetables and fruits. This advanced process ensures that our
                  products retain their nutritional value and flavor, offering convenient and high-quality food options to our customers.
                </p>
              </div>
            </article>
            <article>
              <div className="icon">&#128048;</div>
              <div className="content">
                <h2 style={{ color: '#7B0323' }}>2017 - Establishment and Expansion</h2>
                <p>
                  From our official establishment in 2017, we have continually expanded our product offerings and market reach. Our range of animal
                  feed products caters to cattle, horses, pigs, sheep, goats, dogs, cats, poultry, and the fishery industry. Our comprehensive
                  solutions support the diverse needs of animal husbandry and farming sectors.
                </p>
              </div>
            </article>
            <article>
              <div className="icon">&#127974;</div>
              <div className="content">
                <h2 style={{ color: '#7B0323' }}>2023 - Transition to Private Limited</h2>
                <p>
                  On March 4, 2023, we transitioned to Edge Infinium Private Limited, marking a significant milestone in our growth. This change,
                  accompanied by our new GST number 24AAHCE4491R1ZZ, reflects our readiness to scale new heights and serve a broader market with
                  enhanced capabilities and resources.
                </p>
              </div>
            </article>
          </section>
          <div className="image-container">
            <img src="assets/images/about/gallery/white-horse.jpg" alt="Edge Infinium" />
          </div>
        </div>
      </div>
      <div>
        <header>
          <h1 style={{ color: '#7B0323', fontSize: '50px' }}>Our Products and Services</h1>
        </header>
        <div>
          <img style={{ marginTop: '80px', height: '551px' }} src="assets/images/about/gallery/cow.jpg" alt="Cattle Feed" />
        </div>
        <section className="animal-feed">
          <h2 className="animalfeed" style={{ fontSize: '50px', color: '#7B0323' }}>
            Animal Feed
          </h2>
          <div className="feed-grid">
            <div className="feed-item">
              <div className="icon">&#128046;</div>
              <h3 style={{ color: '#7B0323' }}>Cattle Feed</h3>
              <p>Nutrient-rich formulations that ensure optimal health and productivity of cattle.</p>
            </div>
            <div className="feed-item">
              <div className="icon">&#x1F434;</div>
              <h3 style={{ color: '#7B0323' }}>Horse Feed</h3>
              <p>Specially formulated feed that supports the dietary needs and health of horses.</p>
            </div>
            <div className="feed-item">
              <div className="icon">&#x1F416;</div>
              <h3 style={{ color: '#7B0323' }}>Pork Feed</h3>
              <p>High-quality feed that promotes the growth and well-being of pigs.</p>
            </div>
            <div className="feed-item">
              <div className="icon">&#128017; &#128016;</div>
              <h3 style={{ color: '#7B0323' }}>Sheep and Goat Feed</h3>
              <p>Balanced nutrition tailored for sheep and goats, enhancing their health and productivity.</p>
            </div>
            <div className="feed-item">
              <div className="icon">&#128021;&#128008;</div>
              <h3 style={{ color: '#7B0323' }}>Dog and Cat Feed</h3>
              <p>Premium pet food that meets the nutritional requirements of dogs and cats.</p>
            </div>
            <div className="feed-item">
              <div className="icon">&#128020;</div>
              <h3 style={{ color: '#7B0323' }}>Poultry Feed</h3>
              <p>Formulated to ensure the healthy growth and productivity of poultry.</p>
            </div>
            <div className="feed-item">
              <div className="icon">&#128031;</div>
              <h3 style={{ color: '#7B0323' }}>Fish Feed</h3>
              <p>Specialized feed that supports the health and growth of various fish species.</p>
            </div>
          </div>
          <br />
          <p>
            Under the brand names "The Cattle Feed" and "The Animal Feed," our animal feed products are designed to meet the specific nutritional
            needs of each type of animal, ensuring their health and productivity.
          </p>
        </section>
      </div>
      <div>
        <section className="dehydrated-products">
          <article>
            <div className="content">
              <h2 style={{ color: '#7B0323', fontSize: '50px' }}>Global Reach</h2>
              <p style={{ fontSize: '22px' }}>
                With offices in the USA and Europe, Edge Infinium has a strong international presence. We export our products to western countries,
                providing full solutions for animal husbandry and farming. Our global network ensures that we can meet the needs of customers around
                the world, delivering quality products and exceptional service.
              </p>
            </div>
          </article>
        </section>
      </div>
      <div>
        <section style={{ fontSize: '50px', marginTop: '60px' }} className="commitment">
          <div className="container">
            <h2>Our Commitment</h2>
            <div className="commitment-item">
              <h3>Quality Assurance</h3>
              <p>
                At Edge Infinium, quality is at the heart of everything we do. Our state-of-the-art manufacturing processes and rigorous quality
                control measures ensure that our products meet the highest standards. We source the best ingredients and utilize advanced technology
                to produce feed and fertilizers that are both effective and safe.
              </p>
            </div>
            <div className="commitment-item">
              <h3>Sustainability</h3>
              <p>
                We are deeply committed to sustainability and environmental stewardship. Our organic fertilizers are a testament to our dedication to
                promoting sustainable farming practices. By converting cow dung into high-quality fertilizers, we contribute to reducing waste and
                enhancing soil health.
              </p>
            </div>
            <div className="commitment-item">
              <h3>Customer Satisfaction</h3>
              <p>
                Our customers are our top priority. We strive to understand their needs and provide solutions that exceed their expectations. Our team
                of experts is always ready to offer support and guidance, ensuring that our customers achieve the best results with our products.
              </p>
            </div>
          </div>
        </section>
        <div className="img-container">
          <img src="assets/images/about/gallery/dog_cat.jpg" />
        </div>
      </div>
      <div className="visionhead">
        <section>
          <div className="vision-mission-left">
            <h2 style={{ marginTop: '100px' }}>Our Vision</h2>
            <p>
              To be a global leader in the animal feed and agricultural sectors, recognized for our innovation, quality, and commitment to
              sustainability.
            </p>
          </div>
          <div className="vision-mission-right">
            <h2>Our Mission</h2>
            <p>
              To provide superior quality products and services that support the health and productivity of animals and promote sustainable farming
              practices. We aim to build lasting relationships with our customers based on trust, excellence, and mutual growth.
            </p>
          </div>
        </section>
      </div>
      <div>
        <img className="sheepandgoat" src="assets/images/about/gallery/goatsandsheep.jpg" alt="Cattle Feed" />
      </div>
      <div style={{ marginTop: '20px' }}>
        <section className="founder">
          <div className="container">
            <div className="founder-info">
              <div className="founder-image">
                <img src="assets/images/about/gallery/vijaysukla.png" alt="vijay shukla" />
              </div>
              <div className="founder-details">
                <h2>Meet Our Founder</h2>
                <p>
                  Mr. Vijay Shukla, the founder and driving force behind Edge Infinium, brings a wealth of experience and vision to the company. His
                  leadership has been instrumental in guiding the company through its various phases of growth and innovation. Under his guidance,
                  Edge Infinium continues to reach new heights, maintaining a steadfast commitment to quality and customer satisfaction.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="contact">
          <div className="container">
            <h2 style={{ color: '#7B0323', fontSize: '50px' }}>Contact Us</h2>
            <p>
              We invite you to explore our wide range of products and experience the quality and excellence that define Edge Infinium Private Limited.
              For more information about our products and services, please do not hesitate to contact us. We look forward to serving you and building
              lasting relationships.
            </p>
            <a href="contact-us" style={{ backgroundColor: '#7B0323' }} className="btn">
              Contact Now
            </a>
          </div>
        </section>
      </div>
      <section className="products-services">
        <section className="grid-section">
          <div className="container">
            <div className="center-text larger-text" style={{ color: '#7B0323', fontSize: '50px' }}>
              Our Products and Services
            </div>
            <div className="container1">
              <div className="card">
                <h2>Animal Feed</h2>
                <p>
                  Under our well-known brands, "The Cattle Feed" and "The Animal Feed," we offer a wide range of high-quality feed solutions for
                  various animals including cattle, horses, pigs, sheep, goats, dogs, cats, poultry, and fish. Our feeds are formulated to ensure
                  optimal health, growth, and productivity.
                </p>
              </div>
              <div className="card">
                <h2>Dehydrated Vegetables Powder and Flakes</h2>
                <p>
                  We produce and supply dehydrated vegetable powders and flakes, which are nutrient-rich and convenient for use in various culinary
                  applications. These products retain the essential vitamins and minerals of fresh vegetables, providing a healthy option for our
                  customers.
                </p>
              </div>
              <div className="card">
                <h2>Organic Fertilizers</h2>
                <p>
                  Committed to sustainable agriculture, we manufacture organic fertilizers from cow dung. These fertilizers enhance soil health and
                  fertility, promoting sustainable farming practices and contributing to better crop yields.
                </p>
              </div>
            </div>
            <div className="container1">
              <div className="card">
                <h2>Quality Assurance</h2>
                <p>
                  At Edge Infinium, quality is at the heart of everything we do. Our state-of-the-art manufacturing processes and rigorous quality
                  control measures ensure that our products meet the highest standards. We source the best ingredients and utilize advanced technology
                  to produce feed and fertilizers that are both effective and safe.
                </p>
              </div>
              <div className="card">
                <h2>Sustainability</h2>
                <p>
                  We are deeply committed to sustainability and environmental stewardship. Our organic fertilizers are a testament to our dedication
                  to promoting sustainable farming practices. By converting cow dung into high-quality fertilizers, we contribute to reducing waste
                  and enhancing soil health.mers.
                </p>
              </div>
              <div className="card">
                <h2>Customer Satisfaction</h2>
                <p>
                  Our customers are our top priority. We strive to understand their needs and provide solutions that exceed their expectations. Our
                  team of experts is always ready to offer support and guidance, ensuring that our customers achieve the best results with our
                  products.
                </p>
              </div>
            </div>
            <div className="container1">
              <div className="card">
                <h2>Empowering India's Marginalized Farmer Communities</h2>
                <p>
                  At Edge Infinium Private Limited, we are dedicated to making a meaningful difference in the lives of India's poor farmers and labor
                  class. Recognizing the challenges they face in securing sustainable livelihoods, we have implemented a multifaceted humanitarian
                  approach aimed at providing them with opportunities, resources, and support to thrive.
                </p>
              </div>
              <div className="card">
                <h2>Farming Jobs and Economic Opportunities</h2>
                <p>
                  We believe in the power of work to uplift communities. Through our initiatives, we offer farming jobs and various employment
                  opportunities to individuals from disadvantaged backgrounds. By engaging them in productive activities, we not only enable them to
                  earn a livelihood but also empower them with skills, dignity, and purpose.
                </p>
              </div>
              <div className="card">
                <h2>Utilizing Rented Land for Social Good</h2>
                <p>
                  Many farmers in India struggle to cultivate their land due to various reasons, including financial constraints or health issues. To
                  address this challenge, we actively engage in renting land from those who are incapable of farming themselves. By utilizing this
                  land for productive purposes, such as agriculture or community projects, we ensure that valuable resources are put to good use while
                  providing opportunities for income generation.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </section>
    </div>
  );
}
