import React, { useState } from 'react';
import axios from 'axios';
import Header from '../components/Husk-n-spices/Header';
import Footer from '../components/Husk-n-spices/Footer';

const contacts = [
  {
    country: 'INDIA',
    address: '617,6th Floor, Saffron Business Park,Nr. Jashoda Nagar Cross Raod, Jashoda Nagar,Maninagar East. Ahmedabad-382445',
    email: ['info@edgeinfinium.com', 'edgeinfinium@gmail.com', 'info@thecattlefeed.com'],
    phone: '+91 9662538245',
    mapSrc:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.0993158281226!2d72.62137307628859!3d22.983375117788068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8557cc44fcaf%3A0xfc4fc893aa5959a6!2sEDGE%20INFINIUM%20PVT%20LTD.!5e0!3m2!1sen!2sin!4v1718446404970!5m2!1sen!2sin',
  },
  {
    country: 'SPAIN',
    address: 'Mr.H.C Gogna Kumar (Managing Director), Calle Marqués de Comillas 4, 03501 Benidorm, España Spain.',
    email: ['gogna@thecattlefeed.com'],
    phone: '+34633833855',
    mapSrc:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3120.8307193529076!2d-0.13546192332131873!3d38.5376701678482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd62056cb6f427ed%3A0x3f632812baa31d68!2sC%2F%20del%20Marqu%C3%A9s%20de%20Comillas%2C%204%2C%2003501%20Benidorm%2C%20Alicante%2C%20Spain!5e0!3m2!1sen!2sin!4v1718612923766!5m2!1sen!2sin',
  },
  {
    country: 'USA',
    address: 'Anju Singh (Director) 453 Farnham Ave Lodi, NJ 07644 USA',
    email: ['singh@thecattlefeed.com'],
    phone: '+001 -201 772 0292',
    mapSrc:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3016.5153303580596!2d-74.09180239999999!3d40.88250939999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f9645f39d5c5%3A0x622060fd16ec7f5a!2s453%20Farnham%20Ave%2C%20Lodi%2C%20NJ%2007644%2C%20USA!5e0!3m2!1sen!2sin!4v1718613506359!5m2!1sen!2sin',
  },
];

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/contact', formData);
      if (response.data.success) {
        setStatusMessage('Message sent successfully');
      } else {
        setStatusMessage('Failed to send message');
      }
    } catch (error) {
      setStatusMessage('An error occurred while sending the message');
    }
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      <h1 style={{ color: '#7B0323', textAlign: 'center', fontSize: '50px' }}>Contact Us</h1>
      <div
        style={{
          maxWidth: '1000px',
          margin: '0 auto',
          fontFamily: 'Arial, sans-serif',
          padding: '20px',
        }}
      >
        <form onSubmit={handleSubmit} method="POST">
          <div style={{ marginBottom: '15px' }}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd', boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)' }}
              required
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd', boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)' }}
              required
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ddd',
                boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)',
                minHeight: '100px',
                resize: 'vertical',
              }}
              required
            ></textarea>
          </div>
          <div style={{ textAlign: 'center' }}>
            <button
              type="submit"
              style={{
                width: '100%',
                backgroundColor: '#7B0323',
                color: '#fff',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                boxShadow: '0 4px 6px rgba(0, 123, 255, 0.2)',
                transition: 'background-color 0.3s, box-shadow 0.3s',
              }}
              onMouseOver={e => (e.currentTarget.style.backgroundColor = '#7B0310')}
              onMouseOut={e => (e.currentTarget.style.backgroundColor = '#7B0323')}
            >
              Submit
            </button>
          </div>
        </form>
        {statusMessage && <p style={{ textAlign: 'center', color: '#7B0323' }}>{statusMessage}</p>}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        {contacts.map((contact, index) => (
          <div
            key={index}
            style={{
              backgroundColor: '#fff',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              margin: '20px',
              padding: '20px',
              maxWidth: '500px',
              transition: 'transform 0.3s',
              fontSize: '18px',
            }}
            onMouseEnter={e => (e.currentTarget.style.transform = 'scale(1.05)')}
            onMouseLeave={e => (e.currentTarget.style.transform = 'none')}
          >
            <h2 style={{ color: '#7B0323', marginBottom: '10px', fontSize: '28px' }}>{contact.country}</h2>
            <p style={{ color: '#666', margin: '5px 0', fontSize: '20px' }}>{contact.address}</p>
            <div style={{ color: '#666', margin: '5px 0', fontSize: '20px' }}>
              Email:
              {contact.email.map((email, idx) => (
                <span
                  key={idx}
                  style={{ display: 'block', cursor: 'pointer' }}
                  onMouseEnter={e => (e.currentTarget.style.color = '#ff6b6b')}
                  onMouseLeave={e => (e.currentTarget.style.color = '#666')}
                >
                  {email}
                </span>
              ))}
            </div>
            {contact.phone && (
              <div style={{ color: '#666', margin: '5px 0', fontSize: '20px' }}>
                Phone:
                <span
                  style={{ display: 'block', cursor: 'pointer' }}
                  onMouseEnter={e => (e.currentTarget.style.color = '#ff6b6b')}
                  onMouseLeave={e => (e.currentTarget.style.color = '#666')}
                >
                  {contact.phone}
                </span>
              </div>
            )}
            {/* Embed Google Maps iframe */}
            {contact.mapSrc && (
              <div style={{ marginTop: '10px' }}>
                <iframe
                  title={`Map-${index}`}
                  width="100%"
                  height="200"
                  frameBorder="0"
                  style={{ border: 0, borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                  src={contact.mapSrc}
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
