import React from 'react';
import image1 from '../images/carousel/herbels-products.jpg';
import image2 from '../images/carousel/spices6.jpeg';
import image3 from '../images/carousel/a-grade-soybean-meal.jpg';
import image4 from '../images/carousel/peel-powder.jpg';
import image5 from '../images/carousel/Dehydrated-Vegetable-Powder.jpeg';
import image6 from '../images/carousel/Spray-Drayed-Fruits-Powder.jpg';
import image7 from '../images/carousel/Spray Drayed Vegetable Powder.jpeg';
import ReactOwlCarousel from 'react-owl-carousel';
import image8 from '../images/carousel/Dehydrated-Vegetable-Flakes-And-Cube.jpg';
import Footer from '../components/Husk-n-spices/Footer';
import Haldiram from '../../src/images/carousel/haldirams-logo.png';
import Meggi from '../../src/images/carousel/Maggi_log.png';
import TataQ from '../../src/images/carousel/tataq.png';
import Thetasteofcompany from '../../src/images/carousel/The Taste Company.jpg';
import Kitchenofindia from '../../src/images/carousel/Kitchens of India.png';
import Aashirvaad from '../../src/images/carousel/Aashirvaad.png';
import MTR from '../../src/images/carousel/MTR_LOGO.png';
import Cupnoodles from '../../src/images/carousel/cup-noodles.webp';
import Slurrp from '../../src/images/carousel/Slurrp.png';
import Kelloggs from '../../src/images/carousel/Kelloggs-Logo.png';
import Mom from '../../src/images/carousel/Mom_Logo.webp';
import Murginns from '../../src/images/carousel/Murginns_logo.webp';
import Bogatchi from '../../src/images/carousel/Bogatchi.png';
import Patanjali from '../../src/images/carousel/Patanjali_Logo.png';
import Dabur from '../../src/images/carousel/dabur-logo.png';
import ITC from '../../src/images/carousel/ITC_Limited_Logo.png';
import Maccain from '../../src/images/carousel/Maccain.png';
import PepsiCo from '../../src/images/carousel/PepsiCo_logo.png';

export default function HusknSpicesHome() {
  const CarouselImages = [
    {
      id: 0,
      image: 'soyabean',
      path: image3,
    },
    {
      id: 1,
      image: 'peel-powder',
      path: image4,
    },
    {
      id: 2,
      image: 'Dehydrated-Vegetable-Powder',
      path: image5,
    },
    {
      id: 3,
      image: 'Dehydrated-Vegetable-Powder',
      path: image6,
    },
    {
      id: 4,
      image: 'herbels-products',
      path: image1,
    },
    {
      id: 5,
      image: 'spices',
      path: image2,
    },
    {
      id: 6,
      image: 'Spray-Drayed-Vegetable-Powder',
      path: image7,
    },
    { id: 7, image: 'Dehydrated-Vegetable-Flakes-And-Cube.jpg', path: image8 },
  ];

  return (
    <div style={{ margin: '25px' }}>
      <div style={{ textAlign: 'center' }}>
        <div className="col-lg-12">
          <ReactOwlCarousel
            style={{ cursor: 'pointer' }}
            className="owl-theme2"
            autoplayTimeout={2700}
            animateIn={'easeOut'}
            autoplay={true}
            items={1}
            loop={true}
            margin={10}
          >
            {CarouselImages.map((item, index) => (
              <div className="item" key={index}>
                <img src={item.path} alt={item.image} className="carousel-img" />
              </div>
            ))}
          </ReactOwlCarousel>
        </div>
      </div>
      <header>
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1 style={{ color: '#7B0323', fontSize: '50px', margin: '10px 0' }}>Welcome to EDGE INFINIUM</h1>
          <h1 style={{ color: '#7B0323', fontSize: '50px', margin: '10px 0' }}>Proudly Produced in India</h1>
          <div style={{ color: '#7B0323', fontSize: '20px', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto' }}>
            At EDGE INFINIUM, we are dedicated to delivering the finest in dehydrated food products, spices, and seasonings through our esteemed
            brand, HUSK N SPICES. Since our inception in 2007, we have built a legacy grounded in quality, innovation, and excellence, making us a
            trusted name in the industry.
          </div>
        </div>
      </header>
      <header>
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1 style={{ color: '#7B0323', fontSize: '50px', margin: '10px 0' }}>Who We Are</h1>
          <div style={{ color: '#7B0323', fontSize: '20px', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto' }}>
            Founded in 2007, EDGE INFINIUM commenced its journey in the spice industry with a commitment to delivering superior quality and unmatched
            flavor. In 2009, we strategically expanded into the food dehydration industry, introducing an array of dehydrated vegetables and fruits.
            Our relentless pursuit of innovation and excellence has enabled us to continuously diversify and elevate our product offerings.
          </div>
        </div>
      </header>
      <main style={{ padding: '20px' }}>
        <section>
          <div style={{ textAlign: 'center', margin: '20px auto' }}>
            <h2 style={{ color: '#7B0323', fontSize: '50px', marginBottom: '20px' }}>Our Products</h2>
            <div style={{ color: '#7B0323', fontSize: '20px', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto' }}>
              Explore our comprehensive portfolio of products, meticulously crafted to meet the highest standards of quality, purity, and taste.
            </div>
          </div>
        </section>
        <section className="features-grid">
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Dehydrated Vegetable Flakes and Powder</h3>
            <p>Enhance the flavor and nutritional value of culinary creations.</p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Dehydrated Fruit Flakes and Powder</h3>
            <p>Ideal for a variety of applications, offering natural sweetness and health benefits.</p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Spray Dried Vegetable Powder</h3>
            <p>Versatile ingredient for soups, sauces, and snacks, ensuring a rich taste and vibrant color.</p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Spray Dried Fruit Powder</h3>
            <p>Convenient way to add the essence of fruits to any recipe, maintaining integrity of flavor and nutrition.</p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Seasoning</h3>
            <p>
              Expertly crafted blends to elevate the taste of any dish, delivering consistency and excellence. Caramel Food Colours: High-quality food
              coloring solutions that enhance the visual appeal of food and beverages.
            </p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Herbal Products</h3>
            <ul>
              <p>Natural and pure herbal ingredients for health and wellness applications.</p>
            </ul>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Peel Powder</h3>
            <ul>
              <p> Nutrient-rich powders made from fruit and vegetable peels, promoting sustainability and health.</p>
            </ul>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Feed Grade</h3>
            <ul>
              <p>Premium quality products designed to meet the nutritional needs of livestock and poultry. Global Presence</p>
            </ul>
          </div>
        </section>
      </main>
      <header>
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <div style={{ color: '#7B0323', fontSize: '20px', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto' }}>
            With a strong presence in Spain, the USA, and Russia, EDGE INFINIUM has established itself as a global leader in the food dehydration
            industry. Our international footprint allows us to cater to a diverse clientele, ensuring that our high-quality products reach customers
            worldwide.
          </div>
        </div>
      </header>
      <header>
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1 style={{ color: '#7B0323', fontSize: '50px', margin: '10px 0' }}>Why Choose Us</h1>
          <div style={{ color: '#7B0323', fontSize: '20px', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto' }}>
            At EDGE INFINIUM, we pride ourselves on:
          </div>
        </div>
      </header>
      <main style={{ padding: '20px' }}>
        <section className="features-grid">
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Unmatched Quality</h3>
            <p>Rigorous quality control measures to ensure the highest standards of purity and taste.</p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Innovation</h3>
            <p>State-of-the-art manufacturing facilities equipped with advanced technology.</p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Sustainability</h3>
            <p> Commitment to environmentally friendly practices and sustainable sourcing.</p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Customer Satisfaction</h3>
            <p> Customized solutions and exceptional service tailored to meet our clients' needs.</p>
          </div>
        </section>
      </main>
      <section>
        <div style={{ textAlign: 'center', margin: '20px auto' }}>
          <h2 style={{ color: '#7B0323', fontSize: '50px', marginBottom: '20px' }}>Our Mission</h2>
          <div style={{ color: '#7B0323', fontSize: '20px', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto' }}>
            Our mission is to harness the goodness of nature to provide superior quality products that enrich the lives of our customers. We aim to
            lead the way in the food dehydration industry, continuously innovating and adapting to meet the ever-evolving needs of the global market.{' '}
          </div>
        </div>
      </section>
      <section>
        <div style={{ textAlign: 'center', margin: '20px auto' }}>
          <h2 style={{ color: '#7B0323', fontSize: '50px', marginBottom: '20px' }}>Join Us</h2>
          <div style={{ color: '#7B0323', fontSize: '20px', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto' }}>
            Experience the essence of quality and excellence with EDGE INFINIUM and our brand HUSK N SPICES. Explore our diverse range of products and
            discover how we can help you bring the best to your table.
          </div>
        </div>
      </section>
      <section>
        <div style={{ textAlign: 'center', margin: '20px auto' }}>
          <h2 style={{ color: '#7B0323', fontSize: '50px', marginBottom: '20px' }}>Contact Us</h2>
          <div style={{ color: '#7B0323', fontSize: '20px', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto' }}>
            We invite you to learn more about our products and services. Contact us today and join us on our journey to bring the finest dehydrated
            food products, spices, and seasonings to the world.
          </div>
        </div>
      </section>
      <section>
        <div style={{ textAlign: 'center', margin: '20px auto' }}>
          <h2 style={{ color: '#7B0323', fontSize: '50px', marginBottom: '20px' }}>Discover the Difference with EDGE INFINIUM.</h2>
        </div>
      </section>

      <main style={{ padding: '20px' }}>
        <div style={{ textAlign: 'center', margin: '20px auto' }}>
          <h2 style={{ color: '#7B0323', fontSize: '50px', marginBottom: '20px' }}>our prominent clients</h2>
        </div>
        <section className="features-grid">
          <div className="card">
            <img src={Haldiram} alt="haldiram" style={{ maxWidth: '120px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Haldiram’s</h3>
          </div>
          <div className="card">
            <img src={Meggi} alt="Maggie" style={{ maxWidth: '120px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Maggi</h3>
          </div>
          <div className="card">
            <img src={TataQ} alt="tataq" style={{ maxWidth: '150px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Tata Q</h3>
          </div>
          <div className="card">
            <img src={Thetasteofcompany} alt="the taste of company" style={{ maxWidth: '70px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>The Taste Company</h3>
          </div>

          <div className="card">
            <img src={Kitchenofindia} alt="Kitchenofindia" style={{ maxWidth: '120px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Kitchens of India</h3>
          </div>
          <div className="card">
            <img src={Aashirvaad} alt="Aashirvaad" style={{ maxWidth: '120px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Aashirvaad</h3>
          </div>
          <div className="card">
            <img src={MTR} alt="MTr" style={{ maxWidth: '150px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>MTR</h3>
          </div>
          <div className="card">
            <img src={Cupnoodles} alt="Cupnoodles" style={{ maxWidth: '120px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Cup Noodles</h3>
          </div>
          <div className="card">
            <img src={Slurrp} alt="Slurrp" style={{ maxWidth: '100px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Slurrp</h3>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Gits</h3>
          </div>
          <div className="card">
            <img src={Kelloggs} alt="Kelloggs" style={{ maxWidth: '150px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Kellogg’s</h3>
          </div>
          <div className="card">
            <img src={Mom} alt="Mom-Meal of The Moment" style={{ maxWidth: '190px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>MOM – Meal of The Moment</h3>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Riwayat</h3>
          </div>
          <div className="card">
            <img src={Murginns} alt="Murginns" style={{ maxWidth: '190px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Murginns</h3>
          </div>
          <div className="card">
            <img src={Bogatchi} alt="Bogatchi" style={{ maxWidth: '120px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Bogatchi</h3>
          </div>
          <div className="card">
            <img src={Patanjali} alt="Patanjali" style={{ maxWidth: '190px', marginTop: '37px' }} />
            <h3 style={{ color: '#7B0323', marginTop: '50px' }}>Patanjali</h3>
          </div>
          <div className="card">
            <img src={Dabur} alt="Dabur" style={{ maxWidth: '120px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Dabur</h3>
          </div>
          <div className="card">
            <img src={ITC} alt="ITC" style={{ maxWidth: '100px', margin: '17px' }} />
            <h3 style={{ color: '#7B0323' }}>Itc</h3>
          </div>
          <div className="card">
            <img src={Maccain} alt="Maccain" style={{ maxWidth: '140px', margin: '17px' }} />

            <h3 style={{ color: '#7B0323' }}>Maccain</h3>
          </div>
          <div className="card">
            <img src={PepsiCo} alt="PepsiCo" style={{ maxWidth: '160px', marginTop: '17px' }} />
            <h3 style={{ color: '#7B0323', marginTop: '50px' }}>PepsiCo</h3>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
