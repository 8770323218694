import React from 'react';
import { useParams } from 'react-router-dom';
import { data as horseData } from '../HorseData/ProductsForHorses';
import { data as HnSData } from '../HusknSpicesData/ProductFeedGradeVegetableAndGrainPowder';
import CattleFeedHeader from '../components/Cattle-feed/Header';
import HuskNSpicesHeader from '../components/Husk-n-spices/Header';
import CattleFeedFooter from '../components/Cattle-feed/Footer';
import HuskNSpicesFooter from '../components/Husk-n-spices/Footer';

export default function Product() {
  const { pId } = useParams();
  const cf = horseData.find(item => item.id === pId);
  const hs = HnSData.find(item => item.id === pId);
  const details = cf || hs;

  const isHuskNSpices = !!hs;

  console.log('details -> ', details);

  if (!details) {
    return <div>Product not found</div>;
  }

  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      {isHuskNSpices ? <HuskNSpicesHeader /> : <CattleFeedHeader />}
      <section className="about-inner" style={{ paddingBottom: 10 }}>
        <div className="container pt-xl-5 pt-lg-3">
          <div className="row">
            <div className="col-lg-8 w3lsits_works-grid mt-xl-4">
              <div className="wthree-bottom">
                <div id="c42" className="frame frame-default frame-type-text frame-layout-0">
                  <h3 style={{ color: '#7B0323' }} className="title-w3 mb-5 font-weight-bold">
                    {details?.name}
                  </h3>
                  <p>{details?.description}</p>
                  <h6 style={{ color: '#7B0323' }}>Benefits:</h6>
                  {details?.benefits
                    ? details?.benefits?.map(benefit => (
                        <p key={benefit?.title}>
                          <strong style={{ color: '#7B0323' }}>{benefit?.title}:</strong> {benefit?.description}
                        </p>
                      ))
                    : 'No benefits provided'}
                  <h6 style={{ color: '#7B0323' }}>Uses:</h6>
                  {details?.uses?.map(use => <p key={use?.description}>{use?.description}</p>) || 'No Uses'}
                </div>
              </div>
            </div>
            <div className="col-lg-4 w3lsits_works-grid1 mt-lg-0 mt-sm-5 mt-4">
              <div id="c43" className="frame frame-default frame-type-image frame-layout-0">
                <div className="ce-image ce-center ce-above">
                  <div className="ce-gallery" data-ce-columns={1} data-ce-images={1}>
                    <div className="ce-outer">
                      <div className="ce-inner">
                        <div className="ce-row">
                          <div className="ce-column">
                            <figure className="image">
                              <img
                                className="image-embed-item"
                                src={details.image}
                                alt=""
                                style={{
                                  objectFit: 'contain',
                                  objectPosition: 'center',
                                  height: 300,
                                  width: 300,
                                }}
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-inner" style={{ paddingTop: 0 }}>
        <div className="container pt-xl-5 pt-lg-3">
          <div className="row">
            <div className="col-lg-12">
              <div id="c55" className="frame frame-default frame-type-text frame-layout-0">
                <h6 style={{ color: '#7B0323' }}>Specifications :</h6>
                {details?.specifications?.map(specification => (
                  <p key={specification?.title}>
                    <strong style={{ color: '#7B0323' }}>{specification?.title}:</strong> {specification?.description}
                  </p>
                )) || 'No specifications'}
                <p>
                  <strong style={{ color: '#7B0323' }}>Ingredient Values (Approximate per 100g):</strong>
                </p>
                <table style={{ width: 400 }} className="contenttable">
                  <tbody>
                    {details?.ingredientValues?.map(ingredient => (
                      <tr key={ingredient?.title}>
                        <td style={{ color: '#7B0323' }}>{ingredient?.title}</td>
                        <td>{ingredient?.value}</td>
                      </tr>
                    )) || 'No Ingredient Values'}
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <h6 style={{ color: '#7B0323' }}>Feeding instruction</h6>
                <ol>
                  {details?.feedingInstruction?.map(instruction => (
                    <li key={instruction?.title}>
                      <strong style={{ color: '#7B0323' }}>{instruction?.title}</strong> {instruction?.description}
                    </li>
                  )) || 'No feeding instruction'}
                </ol>
                <p />
              </div>
            </div>
          </div>
        </div>
      </section>
      {isHuskNSpices ? <HuskNSpicesFooter /> : <CattleFeedFooter />}
    </div>
  );
}
