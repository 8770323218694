import React from 'react';
import image1 from '../images/carousel/Dog-and-Cat.jpg';
import image2 from '../images/carousel/Poultry.jpg';
import image3 from '../images/carousel/cowonfarm.webp';
import image4 from '../images/carousel/horses.jpg';
import image5 from '../images/carousel/mammals.jpg';
import image6 from '../images/carousel/pork.jpg';
import image7 from '../images/carousel/sheep-and-goat.png';
import ReactOwlCarousel from 'react-owl-carousel';
import image8 from '../images/carousel/fish.jpg';
import Footer from '../components/Cattle-feed/Footer';

export default function CattleFeedHome() {
  const CarouselImages = [
    {
      id: 0,
      image: 'cowonfarm',
      path: image3,
    },
    {
      id: 1,
      image: 'horses',
      path: image4,
    },
    {
      id: 2,
      image: 'Pig',
      path: image5,
    },
    {
      id: 3,
      image: 'sheep-and-goat',
      path: image6,
    },
    {
      id: 4,
      image: 'Dogs and Cat',
      path: image1,
    },
    {
      id: 5,
      image: 'Poultry',
      path: image2,
    },
    {
      id: 6,
      image: 'sheep-and-goat',
      path: image7,
    },
    { id: 7, image: 'fish', path: image8 },
  ];

  return (
    <div style={{ margin: '25px' }}>
      <div style={{ textAlign: 'center' }}>
        <div className="col-lg-12">
          <ReactOwlCarousel
            style={{ cursor: 'pointer' }}
            className="owl-theme2"
            autoplayTimeout={2700}
            animateIn={'easeOut'}
            autoplay={true}
            items={1}
            loop={true}
            margin={10}
          >
            {CarouselImages.map((item, index) => (
              <div className="item" key={index}>
                <img src={item.path} alt={item.image} className="carousel-img" />
              </div>
            ))}
          </ReactOwlCarousel>
        </div>
      </div>
      <header>
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1 style={{ color: '#7B0323', fontSize: '50px', margin: '10px 0' }}>Made in India Animal Feed</h1>
          <h1 style={{ color: '#7B0323', fontSize: '50px', margin: '10px 0' }}>Proudly Produced in India</h1>
        </div>
      </header>
      <main style={{ padding: '20px' }}>
        <section>
          <div style={{ textAlign: 'center', margin: '20px auto' }}>
            <h2 style={{ color: '#7B0323', fontSize: '50px', marginBottom: '20px' }}>About Our Product</h2>
            <div style={{ color: '#7B0323', fontSize: '20px', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto' }}>
              Our animal feed is manufactured locally, supporting Indian farmers and contributing to the domestic economy. We source the highest
              quality ingredients from trusted Indian farms.
            </div>
          </div>
        </section>
        <section className="features-grid">
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Vegetarian and Vegan</h3>
            <p>
              100% Vegetarian and Vegan: Formulated without any animal-derived ingredients, our feed is suitable for both vegetarian and vegan diets,
              ensuring ethical and sustainable nutrition for your animals.
            </p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Gluten-Free</h3>
            <p>
              Gluten-Free: Carefully crafted to be free from gluten, our feed is safe for animals with gluten sensitivities and helps in maintaining
              optimal digestive health.
            </p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Non-GMO</h3>
            <p>
              Non-GMO Ingredients: We use only non-genetically modified organisms in our feed, providing a natural and safe diet for your animals. Our
              commitment to non-GMO ingredients ensures the highest standards of purity and quality.
            </p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>No Plastic</h3>
            <p>
              Plastic-Free Packaging: Our packaging is entirely free from plastic, aligning with our commitment to sustainability and reducing
              environmental impact. We use eco-friendly materials that are biodegradable and recyclable.
            </p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Easy to Digest</h3>
            <p>
              Easily Digestible: Our feed is formulated to be easily digestible, promoting better nutrient absorption and overall health. It supports
              optimal digestion, reducing the risk of digestive issues and enhancing animal well-being.
            </p>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Key Benefits</h3>
            <ul>
              <li>Nutritionally Balanced: Provides essential nutrients required for the healthy growth and development of animals.</li>
              <li>High-Quality Ingredients: Made from premium-grade, locally sourced plant-based ingredients.</li>
              <li>Sustainable and Ethical: Reflects our commitment to sustainable practices and ethical animal care.</li>
            </ul>
          </div>
          <div className="card">
            <h3 style={{ color: '#7B0323' }}>Ideal For</h3>
            <ul>
              <li>All Types of Livestock: Suitable for various animals, including dairy cattle, poultry, goats, sheep, and more.</li>
              <li>
                Health-Conscious Consumers: Perfect for those looking to provide their animals with a diet that aligns with vegetarian, vegan, and
                gluten-free principles.
              </li>
            </ul>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
