import React from 'react';
import Header from '../components/Husk-n-spices/Header';
import ListItem from '../components/ListItem';
import { data } from '../HusknSpicesData/ProductFeedGradeVegetableAndGrainPowder';
import Footer from '../components/Husk-n-spices/Footer';

export default function DehydratedVegetablePowder() {
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      {data
        .filter(i => i.type === 'DehydratedVegetablePowder')
        .map(dehydratedvegetablePowder => {
          return (
            <ListItem
              name={dehydratedvegetablePowder.name || 'No Name'}
              description={dehydratedvegetablePowder.description || 'No Description'}
              ingredientValues={dehydratedvegetablePowder.ingredientValues || 'No Ingredient Values'}
              image={dehydratedvegetablePowder.image}
              id={dehydratedvegetablePowder.id}
            />
          );
        })}
      <Footer />
    </div>
  );
}
