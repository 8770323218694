import React from 'react';
import Header from '../components/Husk-n-spices/Header';
import ListItem from '../components/ListItem';
import { data } from '../HusknSpicesData/ProductFeedGradeVegetableAndGrainPowder';
import Footer from '../components/Husk-n-spices/Footer';

export default function VegetablePowder() {
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      {data
        .filter(i => i.type === 'VegetableGrainPowder')
        .map(vegetableGrainPowder => {
          return (
            <ListItem
              name={vegetableGrainPowder.name || 'No Name'}
              description={vegetableGrainPowder.description || 'No Description'}
              ingredientValues={vegetableGrainPowder.ingredientValues || 'No Ingredient Values'}
              image={vegetableGrainPowder.image}
              id={vegetableGrainPowder.id}
            />
          );
        })}
      <Footer />
    </div>
  );
}
