import React from 'react';
import Header from '../components/Husk-n-spices/Header';
import ListItem from '../components/ListItem';
import { data } from '../HusknSpicesData/ProductFeedGradeVegetableAndGrainPowder';
import Footer from '../components/Husk-n-spices/Footer';

export default function HerbalProducts() {
  return (
    <div id="wrapperParallax" className="wrapper wrapper-boxed clearfix">
      <Header />
      {data
        .filter(i => i.type === 'HerbalProducts')
        .map(herbalproducts => {
          return (
            <ListItem
              name={herbalproducts.name || 'No Name'}
              description={herbalproducts.description || 'No Description'}
              ingredientValues={herbalproducts.ingredientValues || 'No Ingredient Values'}
              image={herbalproducts.image}
              id={herbalproducts.id}
            />
          );
        })}
      <Footer />
    </div>
  );
}
